import {
    CHECK_MEMBERSHIP,
    GET_SUBCTION_DETAIL,
    YOUTUBE_SUMMARIZE_SUCCESS,
    YOUTUBE_SUMMARIZE_ERROR,
} from "../types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { toast } from "react-toastify";
import { youtubeSummarizeRep } from "../../repository/YtRepository";

var payment = RepositoryFactory.get("payment");
export const Loading = (val) => async (dispatch) => {
    dispatch({ type: "LOADER", payload: val });
};
// actions.js

export const youtubeSummarizeAction =
    (youtubeURL, onSuccess, onError) => async (dispatch) => {
        try {
            // dispatch({
            //     type: YOUTUBE_SUMMARIZE_SUCCESS,
            //     payload: null,
            // });
            const response = await youtubeSummarizeRep(youtubeURL);
            const responseData = response.data;
            if (responseData.status_code === 200) {
                dispatch({
                    type: YOUTUBE_SUMMARIZE_SUCCESS,
                    payload: responseData,
                });
                // console.log(responseData,"its responseData.. newe")
                // Trigger success toast and navigate using onSuccess callback
                onSuccess("Summary generated successfully!");
            } else if (responseData.status_code === 500) {
                throw new Error(
                    responseData.detail || "Summary could not be generated."
                );
            } else {
                throw new Error("An unexpected error occurred.");
            }
        } catch (error) {
            onError("Summary could not be generated please try another video", error);
            dispatch({
                type: YOUTUBE_SUMMARIZE_ERROR,
                payload: error,
            });
        }
    };

export const addPaymentAction = (payload, onSuccess) => async (dispatch) => {
    try {
        const { data } = await payment.addPayment(payload);
        if (data?.detail?.Success) {
            toast.success(data?.detail?.Message);
            // toast.success(data?.message);
            onSuccess(true);
        } else {
            toast.warn(data?.detail);
            // toast.error(data?.message);
            onSuccess(false);
        }
    } catch (error) {
        onSuccess(false);
        console.error("Error:", error);
    }
};

export const CheckMemberShip = (id) => async (dispatch) => {
    try {
        const { data } = await payment.checkMemberShipRep(id);
        if (data?.detail?.Success) {
            dispatch({ type: CHECK_MEMBERSHIP, id: data?.detail });
            // alert(data?.detail?.Message);
            // toast.success(data?.message);
            // onSuccess(true);
        } else {
            // alert(data?.detail);
            // toast.error(data?.message);
            // onSuccess(false);
        }
    } catch (error) {
        // onSuccess(false);
        console.error("Error:", error);
    }
};
export const GetSubscriptionDetail = (payload) => async (dispatch) => {
    try {
        let storedUserData = JSON.parse(localStorage.getItem("authUser"));
        // console.log(storedUserData,"its.//")
        let userData = { ...storedUserData };
        const { data } = await payment.getSubscriptionDetailRep(payload);
        if (data?.status_code === 200) {
            if (storedUserData.user.SubscriptionStatus) {
                // Update the SubscriptionStatus in the user data
                // storedUserData.user.SubscriptionStatus = data?.detail?.Status;

                // localStorage.setItem(
                //     "authUser",
                //     JSON.stringify(storedUserData)
                // );
                userData.user.SubscriptionStatus = data?.detail?.Status;
                dispatch({ type: "LOGIN", payload: userData.user });
            }

            dispatch({ type: GET_SUBCTION_DETAIL, payload: data?.detail });
            // alert(data?.detail?.Message);
            // toast.success(data?.message);
            // onSuccess(true);
        } else {
            userData.user.SubscriptionStatus = "No Subscritpion";

            dispatch({ type: "LOGIN", payload: userData.user });
            dispatch({ type: GET_SUBCTION_DETAIL, payload: {} });

            // alert(data?.detail);
            // toast.error(data?.message);
            // onSuccess(false);
        }
    } catch (error) {
        // onSuccess(false);
        console.error("Error:", error);
    }
};

export const reSubscibeAction = (email, onSuccess) => async (dispatch) => {
    try {
        await dispatch(Loading(true));
        const { data } = await payment.reSubscribrRep(email);
        if (data?.detail?.Success) {
            await dispatch(Loading(false));
            alert(data?.detail?.Message);
            onSuccess();
            // toast.success(data?.message);
        } else {
            alert(data?.detail);
            await dispatch(Loading(false));
            // toast.error(data?.message);
        }
    } catch (error) {
        await dispatch(Loading(false));
        console.error("Error:", error);
    }
};

export const cancelSubscriptionAction =
    (email, onSuccess) => async (dispatch) => {
        try {
            await dispatch(Loading(true));
            const { data } = await payment.cancelSubcriptionRep(email);
            if (data?.status_code == 200) {
                await dispatch(Loading(false));
                onSuccess();
                toast.success(data?.detail);
            } else {
                await dispatch(Loading(false));
                toast.error(data?.detail);
            }
        } catch (error) {
            await dispatch(Loading(false));
            console.error("Error:", error);
        }
    };

export const confirmCancelSubscriptionAction =
    (OTP, onSuccess) => async (dispatch) => {
        try {
            await dispatch(Loading(true));
            const { data } = await payment.confirmCancelSubscriptionRep(OTP);
            if (data?.status_code == 200) {
                await dispatch(Loading(false));
                onSuccess();
                toast.success(data?.detail);
                // toast.success("Your Subscription has been cancelled");
            } else {
                await dispatch(Loading(false));
                toast.error(data?.detail);
            }
        } catch (error) {
            await dispatch(Loading(false));
            console.error("Error:", error);
        }
    };
