import AuthView from "../views/auth/AuthView";
import dashboard from "../views/Dashboard";
import PrivacyPolicy from "../views/PrivacyPolicy";
import TermsandConditons from "../views/TermsCondition";
import Acedemic from "../views/Acedemic";
import Login from "../views/auth/Login";
import Signup from "../views/auth/Signup";
import Contactus from "../views/auth/Contactus";
import Payment from "../components/membershipPayment/Payment";
import ForgetPassword from "../views/auth/ForgetPassword";
import OTP from "../views/auth/OTP";
import MyAccount from "../views/auth/MyAccount";
import ResetPassword from "../views/auth/ResetPassword";
import Verify from "../views/auth/Verify";
import SubscriptionOtp from "../views/auth/SubscriptionOtp";
import CancelSubscription from "../views/auth/CancelSubscription";
import Summarize from "../views/Summarize";
import YtSummarize from "../views/YtSummarize";
import Youtube from "../views/Youtube";
let routes = [
    {
        path: "/auth",
        component: AuthView,
        layout: "auth",
    },
    {
        path: "/login",
        component: Login,
        layout: "auth",
    },
    {
        path: "/signup",
        component: Signup,
        layout: "auth",
    },
    {
        path: "/forgetpassword",
        component: ForgetPassword,
        layout: "auth",
    },
    {
        path: "/resetpassword",
        component: ResetPassword,
        layout: "auth",
    },
    {
        path: "/",
        component: dashboard,
        layout: "default",
    },

    {
        path: "/myaccount",
        component: MyAccount,
        layout: "main",
    },
    {
        path: "/otp",
        component: OTP,
        layout: "auth",
    },
    {
        path: "/contactus",
        component: Contactus,
        layout: "default",
    },
    {
        path: "/verify",
        component: Verify,
        layout: "auth",
    },
    {
        path: "/subscription",
        component: Payment,
        layout: "main",
    },
    {
        path: "/privacypolicy",
        component: PrivacyPolicy,
        layout: "default",
    },
    {
        path: "/termsconditon",
        component: TermsandConditons,
        layout: "default",
    },
    {
        path: "/acedemic",
        component: Acedemic,
        layout: "default",
    },
    {
        path: "/summarize/ytsummarize",
        component: YtSummarize,
        layout: "main",
    },
    {
        path: "/summarize",
        component: Summarize,
        layout: "main",
    },
    {
        path: "/subscriptionotp",
        component: SubscriptionOtp,
        layout: "main",
    },
    {
        path: "/cancelsubscription",
        component: CancelSubscription,
        layout: "main",
    },
    {
        path: "/youtube",
        component: Youtube,
        layout: "main",
    },
    
];
export default routes;
