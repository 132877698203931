// chrome.exe --user-data-dir="C://Chrome dev session" --disable-web-security
import axios from "axios";
// const baseDomain = "https://sample-utgao36ebq-uc.a.run.app/";
const baseDomain = "https://backend.quizwhizs.com"
// const baseDomain="https://doberman-musical-scorpion.ngrok-free.app"
// const baseDomain = "https://backend.quizwhizs.com";
// const baseDomain = "http://192.168.1.122:10000";
// const baseDomain = 'http://192.168.18.106:10000';
// const baseDomain = "https://doberman-musical-scorpion.ngrok-free.app";
// const baseDomain = "http://192.168.18.106:10000";
// const baseDomain = "http://35.202.68.227:9000";

// const baseDomain = "http://192.168.18.121:12000"
// const baseDomain = "https://1112-154-192-137-44.ngrok-free.app";
// const baseDomain = "http://localhost:10000";

const baseURL = `${baseDomain}`;

let axiosObj;
axiosObj = axios.create({
    baseURL,
});
axiosObj.interceptors.request.use(
    (config) => {
        // Dynamically set the token for each request
        const ls = JSON.parse(localStorage.getItem("authUser"));
        const token = ls?.token || "";
        config.headers.token = token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default axiosObj;
