import {
    CHECK_MEMBERSHIP,
    GET_SUBCTION_DETAIL,
    YOUTUBE_SUMMARIZE_SUCCESS,
    YOUTUBE_SUMMARIZE_ERROR,
} from "../types";

const initialData = {
    loading: false,
    checkMemberShip: [],
    getSubscptinDetail: [],
    ytSummarize: null, // consider using null to signify no data initially
    error: null, // handle error state
};
const paymentReducer = (state = initialData, action) => {
    const { type, payload } = action; // Correctly destructure here within the function body

    switch (type) {
        case "LOADER":
            return {
                ...state,
                loading: payload,
            };
        case CHECK_MEMBERSHIP:
            return {
                ...state,
                checkMemberShip: payload,
            };
        case YOUTUBE_SUMMARIZE_SUCCESS:
            return {
                ...state,
                ytSummarize: payload, // Use payload, not action.payload
                error: null,  // Reset error on success
            };
        case YOUTUBE_SUMMARIZE_ERROR:
            return {
                ...state,
                error: payload,  // Store error information
                ytSummarize: null,  // Reset ytSummarize on error
            };
        case GET_SUBCTION_DETAIL:
            return {
                ...state,
                getSubscptinDetail: payload,
            };
        default:
            return state;
    }
};

export default paymentReducer;


