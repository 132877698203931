import React from "react";
import { Col, Container, Media, Row } from "reactstrap";
import video from "../../assets/img/copy_163FC935-F281-4ED1-AF7F-B92FD61D7506.mov";

export default function TryDemo() {
    return (
        <Container>
            <Row>
                <Col>
                    <h1 className="my-5 mx-auto text-center text-2xl font-bold lg:text-6xl reviews-heading flex justify-center items-center">
                    Try Demo
                    </h1>

                    <div className="">
                     
                        <Media>
                            <video
                                  controls
                                loop
                                autoPlay
                                muted
                                className="embed-responsive w-[850px] md:mx-16 lg:mx-20 rounded-[10px] mx-auto"
                            >
                                <source src={video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Media>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
