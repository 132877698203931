import React, { useEffect, useState } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import logo from "../../assets/img/quizwhiz-logo.png";
import { confirmCancelSubscriptionAction } from "../../store/actions/paymentAction";

const CancelSubscription = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.payment);
    const history = useHistory();
    const [otp, setOtp] = useState("");
    const handleGoBack = () => {
        history.goBack();
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            confirmCancelSubscriptionAction(otp, () => {
                history.push("/");
            })
        );
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container className="text-center text-white min-h-screen">
            <Row>
                <Col>
                    <img
                        src={logo}
                        alt="logo"
                        className="login-logo mx-auto w-[215px]"
                    />

                    <Form
                        className="mx-auto form-width text-start"
                        onSubmit={handleSubmit}
                    >
                        <IoArrowBackCircleOutline
                            size={30}
                            className="d-block hover:cursor-pointer"
                            onClick={handleGoBack}
                        />
                        <h1 className="text-center text-[30px] font-bold my-[20px]">
                            Verify OTP
                        </h1>
                        <FormGroup className="mb-3">
                            <Label for="otp" className="text-start">
                                Enter the OTP{" "}
                                <span className="text-danger">*</span>
                            </Label>
                            <Input
                                type="text"
                                name="otp"
                                id="otp"
                                placeholder="Enter OTP"
                                required
                                className="py-3 rounded-md text-white bg-transparent placeholder"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                        </FormGroup>
                        <div className="mx-auto text-center">
                            <Button
                                color="primary"
                                className="py-2 my-5 w-75 bg-[#636ce4] font-bold text-xl rounded-lg"
                                disabled={loading}
                                type="submit"
                            >
                                {loading ? <Spinner size="sm" /> : "Verify OTP"}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default CancelSubscription;
