// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import userReducer from "./authReducer";
import countryReducer from "./countryReducer";
import paymentReducer from "./paymentReducer";
import questionReducer from "./questionReducer";
import contactReducer from "./contactReducer";
export let rootReducer = combineReducers({
    authUser: authUserReducer,
    country: countryReducer,
    user: userReducer,
    payment: paymentReducer,
    getQuestionAns: questionReducer,
    contact: contactReducer,
});

export default rootReducer;
