import React, { useEffect, useState } from "react";
import { Col, Row, Container, FormGroup, Input, Label } from "reactstrap";
import logo from "../../assets/img/quizwhiz-logo.png";
import quizlogo from "../../assets/img/quiz-logo.png";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckout from "../StripeCheckout";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
const Payment = () => {
    const location = useLocation();
    const { item } = location.state || {}; // Fallback to an empty object if `state` is undefined
    console.log(item, "its item...");

    const duration = item?.time == "monthly" ? 1 : 12;
    // Now you can use `item` as needed in your component
    // console.log("items>>>>>>", item);
    const [activePayment, setActivePayment] = useState("CardPay");

    const key =
        "pk_live_51OaFhzG9qmiKFEWOTdtLHGvt6KcRMp2OCUwPHklilOcca4R01dTQj0pbk7W2Ycq4LwnPxDys62OjRmJeQeoVLlUc00kN3jS53a";
    let stripePromise = loadStripe(key);

    const history = useHistory();

    const { user } = useSelector((state) => state.authUser);
    const [AutoBilling, setAutoBilling] = useState(true);

    return (
        <Container fluid className="h-full">
            <Row className="h-full">
                {/* Left Column Content */}
                <Col
                    md={6}
                    className="pt-5 sm:px-4 md:px-20 lg:px-32 flex flex-col align-middle justify-center "
                >
                    {/* This is for logo */}
                    <Container className=" ">
                        <Row className="">
                            <Col>
                                <IoArrowBackCircleOutline
                                    size={30}
                                    className="d-block hover:cursor-pointer"
                                    onClick={() => {
                                        history.push("/");
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* <Row>
                        <Col>
                            <img src={logo} alt="logo" className="w-[110px]" />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p className="text-xl text-gray-400 font-semibold">
                                Try Quizwhiz +
                            </p>
                            <h2 className="font-bold text-2xl md:text-4xl lg:text-5xl py-2">
                                {item.price}
                            </h2>
                            <p className="text-lg md:text-xl text-gray-400 font-semibold">
                                then £ 5.99/month
                            </p>
                        </Col>
                    </Row> */}

                        <Row>
                            <Col className="md:flex md:justify-center md:items-center max-w-[500px] mx-auto border-b border-gray-700">
                                <img
                                    src={quizlogo}
                                    alt="quizlogo"
                                    className="w-[90px] mx-auto"
                                />

                                <p>
                                    Quizwhiz +
                                    <span className="text-gray-400">
                                        is a AI-powered Chrome Extension that
                                        helps students ace their tests
                                    </span>
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="flex justify-between py-3 mx-auto">
                                <p>Subtotal</p>
                                <p>£{item?.price}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="flex justify-between py-3 mx-auto">
                                <p>No of Monthes</p>
                                <p>{duration}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="flex justify-between py-3 text-gray-400  border-gray-700 mx-auto">
                                <p>Tax</p>
                                <p>£ 0.00</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="text-gray-400 pt-0 mt-[-19px] border-gray-700 flex justify-end border-b">
                                    {duration} x {item.price}
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="flex justify-between py-3 mx-auto">
                                <p>Total</p>
                                <p>£ {item.price * duration}</p>
                            </Col>
                        </Row>
                    </Container>
                </Col>

                {/* Right Column Content */}
                <Col
                    md={6}
                    className=" px-4 lg:px-20 text-sm pt-5 bg-white text-black "
                >
                    <Row>
                        <Col md={8} className="lg:pl-12">
                            <h1 className="text-2xl font-semibold mb-3">
                                Enter payment details
                            </h1>
                            <FormGroup>
                                <Label
                                    for="paymentDetails"
                                    className="text-gray-400"
                                >
                                    Email
                                </Label>
                                <Input
                                    type="email"
                                    id="email"
                                    readOnly
                                    value={user?.UserEmail}
                                    className="py-3 rounded-md"
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} className="lg:pl-12">
                            <h1 className="text-2xl font-semibold mb-3">
                                Amount
                            </h1>
                            <FormGroup>
                                <Label
                                    for="paymentDetails"
                                    className="text-gray-400"
                                >
                                    Amount
                                </Label>
                                <Input
                                    type="text"
                                    id="email"
                                    readOnly
                                    value={`£ ${item?.price * duration}`}
                                    className="py-3 rounded-md"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} className="lg:pl-12 auto_billin">
                            <h1 className="text-2xl font-semibold mb-3">
                                Auto Billing
                            </h1>
                            <FormGroup className="px-4">
                                <Input
                                    type="checkbox"
                                    checked={AutoBilling}
                                    onChange={(e) => {
                                        setAutoBilling(e.target.checked);
                                    }}
                                    className="py-3 rounded-md "
                                />
                                <Label
                                    for="paymentDetails"
                                    className="text-gray-400 mx-2 mt-2"
                                >
                                    Auto Billing
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{ height: "47vh" }}>
                        <Elements stripe={stripePromise}>
                            <StripeCheckout
                                AutoBilling={AutoBilling}
                                item={item}
                            />
                        </Elements>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Payment;
