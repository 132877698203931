import React, { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Container, Row, Col } from "reactstrap";
const PrivacyPolicy = () => {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container className="pt-6 lg:pt-8 pb-10 px-2 md:px-5 lg:px-14">
            <Row>
                <Col>
                    <h1 className="text-3xl lg:text-5xl font-bold text-center pb-2">
                        Privacy Policy
                    </h1>
                    <p className="text-2xl font-bold  text-center pt-3 ">
                        Dec 12, 2023
                    </p>
                    <p className="text-xl px-4 font-semibold pt-3 pb-10 text-justify">
                        Welcome to our Privacy Policy at Quizwhiz.
                        This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data. We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from https://quizwhizs.com/


                    </p>
                    <h3 className="text-2xl font-bold pl-4 ">Service</h3>

                    <p className="text-xl px-4 text-justify">
                        Service is the https://quizwhizs.com/ website and browser extension operated by QuizWhiz
                    </p>
                    <h3 className="text-2xl font-bold pl-4 py-2">Personal Data</h3>
                    <p className="px-4 text-xl text-justify">
                        Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
                    </p>
                    <h3 className="text-2xl font-bold pl-4 py-2">Data Usage</h3>
                    <p className="text-xl px-4 text-justify">
                        Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                    </p>

                    <h3 className="text-2xl font-bold py-3 pl-4">
                        Cookies
                    </h3>
                    <p className="text-xl pl-4 text-justify">
                        Cookies are small files stored on your device (computer or mobile device).
                    </p>
                    <h3 className="text-2xl font-bold py-3 pl-4">Data Controller</h3>
                    <p className="text-xl py-2 px-4 text-justify">
                        Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.
                    </p>

                    <h3 className="text-2xl font-bold py-3 pl-4"> Data Processors (or Service Providers)</h3>
                    <p className="text-xl py-2 px-4 text-justify">
                        Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.
                    </p>

                    <h3 className="text-2xl font-bold py-3 pl-4"> Data Subject (or User)</h3>
                    <p className="text-xl py-2 px-4 text-justify">
                        Data Subject is any living individual who is using our Service and is the subject of Personal Data.
                    </p>

                    <h3 className="text-2xl font-bold py-3 pl-4"> Information Collection and Use</h3>
                    <p className="text-xl py-2 px-4 text-justify">
                        We collect several different types of information for various purposes to provide and improve our Service to you.
                    </p>

                    <h3 className="text-2xl font-bold py-3 pl-4"> Authentication Information</h3>
                    <p className="text-xl py-2 px-4 text-justify">
                        When you register for an account with us, we collect your password, credentials, security questions, or personal identification number (PIN) to authenticate your account and ensure that only authorized users have access to our services.
                    </p>


                    <h3 className="text-2xl font-bold py-3 pl-4"> Security of Data</h3>
                    <p className="text-xl py-2 px-4 text-justify">
                        The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                    </p>

                    <h3 className="text-2xl font-bold py-3 pl-4"> Link to other sites</h3>
                    <p className="text-xl py-2 px-4 text-justify">
                        Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                    </p>


                    <h3 className="text-2xl font-bold py-3 pl-4"> Change to This Privacy Policy</h3>
                    <p className="text-xl py-2 px-4 text-justify">
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                    </p>
                    <h3 className="text-2xl font-bold py-3 pl-4">Legal Requirements</h3>
                    <p className="text-xl py-2 px-4 text-justify">
                        QuizWhiz   may disclose your Personal Data in the good faith belief that such action is necessary to:

                        <ul className="pl-3 md:pl-8">
                            <li className="list-disc">To comply with a legal obligation</li>
                            <li className="list-disc">To protect and defend the rights or property of QuizWhiz</li>
                            <li className="list-disc">To prevent or investigate possible wrongdoing in connection with the Service</li>
                            <li className="list-disc">To protect the personal safety of users of the Service or the public</li>
                            <li className="list-disc">To protect against legal liability</li>
                        </ul>
                    </p>

                    <h3 className="text-2xl font-bold py-3 px-4"> Contact Us</h3>
                    <p className="text-xl py-2 px-4">
                        If you have any questions about this Privacy Policy, please contact us at:

                        <p className="text-blue-500">
                            <a href="mailto:aiquizwhiz@gmail.com">aiquizwhiz@gmail.com</a>
                        </p>

                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default PrivacyPolicy;
