import { CONTACT } from "../types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { toast } from "react-toastify";

var contactus = RepositoryFactory.get("contactUs");
export const Loading = (val) => async (dispatch) => {
    dispatch({ type: "LOADER", payload: val });
};


export const contactAction = (payload, onSuccess) => async (dispatch) => {
    dispatch(Loading(true));
    try {
        const { data } = await contactus.contactUs(payload);
        if (data?.status_code == 200) {
            toast.success(data?.detail.message);
            onSuccess();
            dispatch(Loading(false));
        } else {
            toast.warn(data?.message);

            dispatch(Loading(false));
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch(Loading(false));
    }
};
