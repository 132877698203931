import AuthRepository from "./AuthRepository";
import CountryRepository from "./CountryRepository";
import paymentRepository from "./paymentRepository";
import askQuestionAnsRep from "./questionAnsRepository";
import ContactRepository from "./ContactRepository";
const repositories = {
    auth: AuthRepository,
    country: CountryRepository,
    payment: paymentRepository,
    questionAns : askQuestionAnsRep,
    contactUs :  ContactRepository,
};
export const RepositoryFactory = {
    get: (name) => repositories[name],
};
