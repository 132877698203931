import React, { useEffect } from 'react';
import Faq from '../components/global/Faq';
import Hero from '../components/global/Hero';
import Pricing from '../components/global/Pricing';
import Reviews from '../components/global/Reviews';
import TryDemo from '../components/global/TryDemo';
const Dashboard = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  // const location=useLocation()

  //     useEffect(() => {
  //         const element = document.getElementById(location.pathname);
  //         if (element) {
  //             element.scrollIntoView({
  //                 behavior: "smooth",
  //                 block: "start",
  //                 inline: "nearest",
  //             });
  //         }
  //     }, [location.pathname]);
  //     console.log("pTH>>",location.pathname)
  //     const currentPath = window.location.pathname;
  // console.log(currentPath);
  return (
    <>
      <Hero />
      <Faq />
      <TryDemo/>
      <Reviews />
      <Pricing />
    </>
  )
}

export default Dashboard