import React, { useState,useEffect } from "react";
import { Container, Row, Col, Input, Button, Spinner } from "reactstrap";
import { FaYoutube } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { youtubeSummarizeAction } from "../store/actions/paymentAction";
import {
    useHistory,
    useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { LoadingModal } from './LoadingModal';

const Summarize = () => {
    const [input, setInput] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [ytLoader, setytLoader] = useState(false);
    const[loaderGif,setLoaderGif]=useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.authUser);
    const { ytSummarize } = useSelector((state) => state.payment);
    // console.log(ytSummarize, "its yt data...");

    useEffect(() => {
        if (user?.SubscriptionStatus !== "active") {
            history.push("/");
        }
    }, [user, history]);

    const handleChange = (event) => {
        setInput(event.target.value);
        setIsValid(true);
    };
    const validateYouTubeUrl = (url) => {
        const regex = /^(https:\/\/youtu.be\/([a-zA-Z0-9_-]+)\?|https:\/\/www.youtube.com\/watch\?v=([a-zA-Z0-9_-]+)(\?[^&\s]*)?|https:\/\/youtu.be\/([a-zA-Z0-9_-]+)$|https:\/\/www\.youtube\.com\/shorts\/([a-zA-Z0-9_-]+)$|https:\/\/youtube\.com\/shorts\/([a-zA-Z0-9_-]+)\?|https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+))/;
        return regex.test(url);
    };
    const handleSubmit = () => {
        if (input.trim() === "") {
            setIsValid(true);
            return;
        }
    if (validateYouTubeUrl(input)) {
        setIsValid(true);
        setLoaderGif(true)
        setytLoader(true);

        let videoId = null;
        const urlObj = new URL(input);
        if (urlObj.hostname === "youtu.be") {
            // Shortened YouTube URL
            videoId = urlObj.pathname.split('/')[1];
        } else if (urlObj.pathname.startsWith("/shorts/")) {
            // YouTube Shorts URL
            videoId = urlObj.pathname.split('/')[2];
        } else {
            // Regular YouTube URL
            videoId = urlObj.searchParams.get("v");
        }
            dispatch(
                youtubeSummarizeAction(
                    input,
                    (onSuccess) => {
                        // onSuccess callback
                        // toast.success(onSuccess);
                        setLoaderGif(false);
                        setytLoader(false);
                        history.push({
                            pathname: "/youtube",
                            state: { videoId, youtubeURL: input } // Ensure correct variable names
                        });
                    },
                    (onError) => {
                        // onError callback
                        setLoaderGif(false);
                        setytLoader(false);
                        toast.error(onError); // Display specific error message from the action
                    }
                )
            );
            
        }
        else {
            setIsValid(false);
        }
    };
    

    return (
        <>
            <Container className="pt-6 lg:pt-8 px-8 pb-10 h-[900px]">
                <Row>
                    <Col>
                    {loaderGif &&<LoadingModal/> }
                        <h1 className="text-3xl lg:text-5xl font-bold text-center ">
                            YouTube Video Summarizer
                        </h1>
                        <p className="text-xl font-semibold pt-3  text-center">
                            Paste a YouTube video link below.
                        </p>
                        
                        <div className="flex justify-center mt-3 items-center ">
                            <FaYoutube
                                className="color_icon relative left-10 z-[1] ml-3 "
                                size={25}
                            />
                            <input
                                type="text"
                                className="summarize_input"
                                placeholder="https://www.youtube.com/watch?v=your_video"
                                value={input}
                                onChange={handleChange}
                            />
                            <div className="relative">
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className={`w-[118px] self-stretch text-center px-4 py-2.5 rounded-tr-lg rounded-br-lg
                                   text-white text-sm font-medium  z-[1] h-[56px] absolute bottom-[-28px] right-[0px] cursor-pointer ${
                                       input ? "bg-blue-500" : "bg-gray-500"
                                   }`}
                                    disabled={ytLoader}
                                >
                                    {ytLoader ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        "Summarize"
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            {!isValid && (
                                <div className="text-red-500">
                                    Please enter a valid URL.
                                </div>
                            )}
                        </div>
                        
                    </Col>
                 
                </Row>
             
            </Container>
        </>
    );
};

export default Summarize;
