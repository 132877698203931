import React, { useEffect, useState } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import { contactAction } from "../../store/actions/contactAction";

const Contactus = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading } = useSelector((state) => state.contact);

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSend = () => {
        const payload = { Email: email, Message: message };

        dispatch(
            contactAction(payload, () => {
                history.push("/");
            })
        );
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className="text-white pb-5 pt-20 lg:vh-100">
            <Row>
                <Col>
                    <Form className="mx-auto form-width text-start">
                        <IoArrowBackCircleOutline
                            size={30}
                            className="d-block cursor-pointer"
                            onClick={() => history.push("/")}
                        />
                        <h1 className="text-center text-[40px] font-bold mt-[10px] mb-[19px]">
                            Contact Us
                        </h1>
                        <FormGroup className="mb-3">
                            <Label for="email" required className="text-start">
                                Email address{" "}
                                <span className="text-danger">*</span>
                            </Label>
                            <Input
                                type="email"
                                required
                                name="email"
                                id="email"
                                placeholder="Enter your email"
                                className="py-4 rounded-md text-white bg-transparent placeholder "
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="message" className="text-start">
                                Message <span className="text-danger">*</span>
                            </Label>
                            <Input
                                type="textarea"
                                name="content"
                                id="content"
                                rows="3"
                                placeholder="Enter your message..."
                                required
                                className="py-4 rounded-md text-white bg-transparent placeholder "
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </FormGroup>

                        <div className="mx-auto text-center">
                            <Button
                                className="py-2 btn w-75 font-bold text-xl bg-[#636ce4] rounded-lg"
                                color="primary"
                                onClick={handleSend}
                            >
                                {loading ? (
                                    <Spinner size={"sm"}></Spinner>
                                ) : (
                                    <>Send &gt;&gt;</>
                                )}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Contactus;
