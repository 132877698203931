// YTRepoitoty.js
import axios from "./Repository";

const YOUTUBE_SUMMARIZE_ENDPOINT = "/Chat/Youtube-Summarization";

export const youtubeSummarizeRep = (youtubeURL) => {
    return axios.post(YOUTUBE_SUMMARIZE_ENDPOINT, {
        YoutubeURL: youtubeURL
    });
};
