import { toast } from "react-toastify";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { GET_SUBCTION_DETAIL } from "../types";
var auth = RepositoryFactory.get("auth");
export const signup = (userDetail, onSuccess) => async (dispatch) => {
    await dispatch(loginLoading(true));

    try {
        const data = await auth.signup(userDetail);

        if (data.data.status_code == 200) {
            dispatch(loginLoading(false));

            // dispatch({ type: "LOGIN", payload: data.detail });
            onSuccess();
        } else {
            toast(data.data?.detail);
            dispatch(loginLoading(false));
        }
    } catch (error) {
        console.error("Sorry you missing or mismatch some fileds");
        dispatch(loginLoading(false));
    }
};
export const verifyUser = (userDetail, onSuccess) => async (dispatch) => {
    await dispatch(loginLoading(true));
    try {
        const { data } = await auth.verifyUser(userDetail);
        if (data.status_code == 200) {
            dispatch(loginLoading(false));
            toast("User Verification Successfully Done!");
            onSuccess();
        } else {
            toast(data?.detail);
            dispatch(loginLoading(false));
        }
    } catch (error) {
        console.error("Sorry you missing or mismatch some fileds");
        dispatch(loginLoading(false));
    }
};
export const login = (userDetail, onSuccess) => async (dispatch) => {
    await dispatch(loginLoading(true));
    try {
        const { data } = await auth.login(userDetail);
        if (data.status_code == 200) {
            dispatch({ type: "LOGIN", payload: data.detail });
            dispatch(loginLoading(false));
            onSuccess();
            toast("Login Successfully.");
        } else {
            toast(data.detail);
            dispatch(loginLoading(false));
        }
    } catch (error) {
        console.error("Sorry you missing or mismatch some fileds");
        dispatch(loginLoading(false));
    }
};
export const getPasswordOtp = (email, onSuccess) => async (dispatch) => {
    await dispatch(loginLoading(true));
    try {
        const { data } = await auth.getPasswordOtp(email);
        if (data.status_code == 200) {
            onSuccess();
            dispatch(loginLoading(false));

            toast(data.detail);
        } else {
            toast(data.detail);
            dispatch(loginLoading(false));
        }
    } catch (error) {
        console.error("Sorry!");
        dispatch(loginLoading(false));
    }
};
export const verifyOtp = (detail, onSuccess) => async (dispatch) => {
    await dispatch(loginLoading(true));
    try {
        const { data } = await auth.verifyOtp(detail);
        if (data.status_code == 200) {
            onSuccess();
            dispatch(loginLoading(false));

            toast(data.detail);
        } else {
            toast(data.detail);
            dispatch(loginLoading(false));
        }
    } catch (error) {
        console.error("Sorry!");
        dispatch(loginLoading(false));
    }
};
export const resetpassword = (detail, onSuccess) => async (dispatch) => {
    await dispatch(loginLoading(true));
    try {
        const { data } = await auth.resetpassword(detail);
        if (data.status_code == 200) {
            onSuccess();
            dispatch(loginLoading(false));

            toast(data.detail);
        } else {
            toast(data.detail);
            dispatch(loginLoading(false));
        }
    } catch (error) {
        console.error("Sorry!");
        dispatch(loginLoading(false));
    }
};
export const logout = (onSuccess) => async (dispatch) => {
    try {
        localStorage.clear();
        dispatch({ type: "LOGOUT" });
        dispatch({ type: GET_SUBCTION_DETAIL, payload: {} });
        // onSuccess();
        toast("Logout Successfully.");
    } catch (error) {
        toast(error.message);
    }
};
export const loginLoading = (val) => async (dispatch) => {
    dispatch({ type: "loading", payload: val });
};
