import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Spinner,
} from "reactstrap";
import logo from "../../assets/img/quizwhiz-logo.png";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/authAction";
const SubscriptionOtp = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.user);
    const [loginDetail, setLoginDetail] = useState({
        email: "",
        password: "",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const submitHandler = () => {
        let obj = {
            Email: loginDetail.email,
            Password: loginDetail.password,
        };
        dispatch(login(obj));
    };

    const history = useHistory();
    const handleGoBack = () => {
        history.goBack();
    };
    return (
        <Container className="text-center text-white min-h-screen">
            <Row>
                <Col>
                    <img
                        src={logo}
                        alt="logo"
                        className="login-logo mx-auto w-[215px]"
                    />

                    <Form
                        className="mx-auto form-width text-start"
                        onSubmit={(e) => {
                            e.preventDefault();
                            submitHandler();
                        }}
                    >
                        {" "}
                        {/* Remove 'mx-auto text-center' classes */}
                        <IoArrowBackCircleOutline
                            size={30}
                            className="d-block hover:cursor-pointer"
                            onClick={handleGoBack}
                        />
                        <h1 className="text-center text-[40px] font-bold mb-[20px]">
                            Cancel Subscription
                        </h1>

                        <div className="mx-auto text-center">
                            <Button
                                color="primary"
                                className="py-2 mt-4 w-75 font-bold text-xl bg-[#636ce4] rounded-lg"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? <Spinner size="sm" /> : "Cancel"}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};
export default SubscriptionOtp;

