import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill'; // import the ReactQuill component
import 'react-quill/dist/quill.snow.css'; // import styles

const TextDisplayForPrint = ({ content, setValue }) => {
    const [editorHtml, setEditorHtml] = useState('');

    useEffect(() => {
        // Assuming 'content' is the HTML content you want to set in the editor
        setEditorHtml(content);
    }, [content]);

    const handleChange = (html) => {
        setEditorHtml(html);
        setValue(html); // Assuming setValue needs the HTML content for some purpose
    };

    return (
     
            <ReactQuill
                theme="snow"
                value={editorHtml}
                onChange={handleChange}
                modules={TextDisplayForPrint.modules}
                formats={TextDisplayForPrint.formats}
            />
      
    );
};  

/* Add modules and formats to customize the toolbar */
TextDisplayForPrint.modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'] // Only these four formatting options
    ],
};

TextDisplayForPrint.formats = [
    'bold', 'italic', 'underline', 'strike' // Allow only these formats
];

export default TextDisplayForPrint;


