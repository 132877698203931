
import React, { useEffect } from 'react';
import footerlogo from '../../assets/img/quizwhiz-logo.png';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetSubscriptionDetail } from '../../store/actions/paymentAction';
const Footer = () => {

    const history = useHistory();
    // NEW CODE STARTS FROM HERER
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.authUser);
    const { checkMemberShip, getSubscptinDetail, loading } = useSelector(
        (state) => state.payment
    );
    useEffect(() => {
        if (user?.UserId) {
            dispatch(GetSubscriptionDetail(user.UserId));
        }
    }, [user?.UserId]);
    return (
        <div className='pt-10 pb-3 px-12 border-t'>
            <div className='row g-4'>
                <div className='col-lg-3 col-md-12 col-12'>
                    <img src={footerlogo} className='-ml-14 md:-pl-0 img-fluid w-[200px]' alt='footer logo' />
                </div>
                <div className='col-lg-3 col-md-4 col-12'>
                    <h1 className='font-bold text-2xl'>Product</h1>
                    <ul className='list-unstyled py-3'>
                        {!getSubscptinDetail?.SubscriptionId && (
                            <li className='py-1'><a href='#pricing' className='text-decoration-none' onClick={() => { history.push("/"); }}>Pricing</a></li>
                        )}
                       <a href='https://chromewebstore.google.com/detail/ngonolnhlffcmincboijpacecmgijpde' className='text-decoration-none'>   <li className='py-1'>Chrome Store</li> </a>
                    </ul>
                </div>
                <div className='col-lg-3 col-md-4 col-12'>
                    <h1 className='font-bold text-2xl'>Company</h1>
                    <ul className='list-unstyled py-3'>
                        <li className='py-1 pl-1'><a href='#faqs' className='text-decoration-none' onClick={() => { history.push("/"); }}>FAQ</a></li>
                        <li className='py-1 pl-1'><Link to="/contactus" className='text-decoration-none'>Contact Us</Link></li>
                    </ul>
                </div>
                <div className='col-lg-3 col-md-4 col-12'>
                    <h1 className='font-bold text-2xl'>Other</h1>
                    <ul className='list-unstyled py-3'>
                        <li className='py-1'><Link to="/privacypolicy" className='text-decoration-none'>Privacy Policy</Link></li>
                        <li className='py-2'><Link to="/termsconditon" className='text-decoration-none my-5'>Terms and Conditions</Link></li>
                        <li className='py-1'><Link to="/acedemic" className='text-decoration-none py-2'>Academic Honesty Policy</Link></li>
                    </ul>
                </div>
            </div>

            <hr className="my-4 w-[70%] bg-white mx-auto" />

            {/* Copyright Notice */}
            <p className="text-center text-gray-300 italic">&copy; 2023 <Link to="/" className="font-semibold mx-1">QuizWhiz</Link> All rights reserved.</p>
        </div>
    );
}
export default Footer;