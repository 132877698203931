import React, { useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
const TermsandConditons = () => {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container className='pt-6 lg:pt-8 pb-10'>
            <Row>
                <Col>
                    <h1 className='text-3xl lg:text-5xl font-bold text-center'>Terms and Conditions</h1>
                    <p className='text-2xl font-bold  text-center py-3'>Dec 12, 2023</p>
                    <p className='text-xl font-semibold  pt-3 pb-20 text-justify px-4'>Welcome to our Privacy Policy at quizwhizs. Your privacy is of utmost importance to us, and we are committed to safeguarding your personal information. This policy outlines how we collect, use, disclose, and protect the data you share with us. By using our services, you agree to the terms outlined in this document. We strive to be transparent about our data practices and provide you with control over your information. Our Privacy Policy is designed to inform you about the type of data we collect, why we collect it, and how it enhances your experience with our services. We adhere to applicable privacy laws and regulations to ensure the confidentiality and security of your information</p>





                    <h2 className='text-3xl px-4  pb-2 mb-1 font-bold'> Agreement between User and https://quizwhizs.com/ </h2>

                    <p className='text-xl text-justify px-4'>Welcome to https://quizwhizs.com/. The https://quizwhizs.com/ website (the "Site") is comprised of various web pages operated by quizwhizs. https://quizwhizs.com/ is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of https://quizwhizs.com/ constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference. By agreeing to these Terms and Conditions, you acknowledge that quizwhizs is meant to assist in ing, but does not guarantee academic success.</p>




                    <h3 className='text-2xl font-bold py-3 px-4'> Privacy </h3>
                    <p className='text-xl py-2 text-justify px-4'>
                        Your use of https://quizwhizs.com/ is subject to QuizWhiz  .'s Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.
                    </p>


                    <h3 className='text-2xl font-bold py-3 px-4'>Electronic Communication</h3>
                    <p className='text-xl py-2 px-4 text-justify'> Visiting https://quizwhizs.com/ or sending emails to QuizWhiz  . constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing</p>


                    <h3 className='text-2xl font-bold py-3 px-4'> Your Account</h3>
                    <p className='text-xl py-2 px-4 text-justify'>If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that QuizWhiz  . is not responsible for third party access to your account that results from theft or misappropriation of your account. QuizWhiz  . and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.</p>


                    <h3 className='text-2xl font-bold py-3 px-4'> Cancellation/Refund Policy</h3>
                    <p className='text-xl py-2 px-4 text-justify'>
                        All purchases are non-refundable. You can cancel your subscription at any time by logging into your account. Your cancellation will take effect at the end of the current paid term.</p>


                    <h3 className='text-2xl font-bold py-3 px-4'> Termination/Access Restriction</h3>
                    <p className='text-xl py-2 text-justify px-4'>
                        QuizWhiz. reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of California and you hereby consent to the exclusive jurisdiction and venue of courts in California in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>

                        <p className='text-xl py-2 px-4 text-justify'> You agree that no joint venture, partnership, employment, or agency relationship exists between you and QuizWhiz. as a result of this agreement or use of the Site. QuizWhiz.'s performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of QuizWhiz.'s right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by QuizWhiz. with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>

                        <p className='text-xl py-2 px-4 text-justify'> Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and QuizWhiz. with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and QuizWhiz. with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>

                    <h3 className='text-2xl font-bold py-3 px-4 text-justify'> Contact Us</h3>
                    <p className='text-xl py-2 px-4 text-justify'>If you have questions about this Privacy Policy, please contact us at
                    </p>
                    <p className="text-blue-500 px-4 text-lg">
                            <a href="mailto:aiquizwhiz@gmail.com">aiquizwhiz@gmail.com</a>
                        </p>
                </Col>
            </Row>
        </Container>
    )
}

export default TermsandConditons;
