import React from "react";
import { Container, Row, Col } from "reactstrap";
// import video from "../../assets/img/copy_42F17EFB-0A14-4F51-9289-A5A9FACAD222.mov";
import video from "../../assets/img/zc2dk9.mp4";
import stand from "../../assets/img/tvBtm.png";

const Hero = () => {
    return (
        <Container className="flex flex-col items-center justify-center py-5 lg:flex-row  xl:gap-y-0 lg:gap-x-1 mt-[30px] ">
            <Col className="flex flex-col lg:mx-20">
                <div className="border-[10px] border-black w-auto lg:w-[450px] mx-auto">
                    <video
                        //  controls
                        loop
                        autoPlay
                        muted
                        src={video}
                        className="w-auto h-auto mx-auto"
                        
                    ></video>
                </div>
                <img src={stand} alt=""/>
            </Col>

            <Col className="px-3 md:px-5">
                <h1 className="text-5xl  font-bold pb-2">
                    Learn 3x Faster
                </h1>
                <h3 className="text-2xl  font-semibold text-[#CACCC9]">
                    Your Personal AI Problem Solver
                </h3>
                {/* <div className='flex flex-col py-3  text-2xl font-semibold gap-y-3  '> */}
                <div className="text-start w-12/12">
                    <h5 className="text-2xl py-1 text-left">
                        AI-powered learning
                    </h5>
                    <h5 className="text-2xl py-1">
                        Works on Canvas + Blackboard
                    </h5>
                    <h5 className="text-2xl py-1">9,572+ Students Helped</h5>
                </div>
                {/* </div> */}

                <div className="pt-2">
                <a href='https://chromewebstore.google.com/detail/ngonolnhlffcmincboijpacecmgijpde' > <button className="p-[10px] lg:text-start text-lg md:text-2xl font-bold bg-[#636CE4] rounded-lg hover:bg-[#484D96] md:mx-0 hover:text-white">Add to Chrome</button> </a>
                </div>
            </Col>
        </Container>
    );
};

export default Hero;
