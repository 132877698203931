import React, { useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
const Acedemic = () => {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container className='pt-6 lg:pt-8 px-8 pb-10'>
            <Row>
                <Col className='px-4'>
                    <h1 className='text-3xl lg:text-5xl font-bold text-center '>Acedemic and Honesty Policy</h1>
                    <p className='text-xl font-semibold pt-8 text-justify'> At  QuizWhiz, we understand the pressures and demands of being a student. Our mission is to provide a reliable and efficient tool to aid in the completion of assignments. But remember, our tool is not intended to facilitate or encourage academic dishonesty. Here are a few guidelines on how to use  QuizWhiz responsibly:</p>



                    <h3 className='text-2xl pt-4 pb-2 font-bold'> Do's </h3>
                    <p className='text-xl pt- text-justify'>
                        <ul className='pl-4 md:pl-8 text-justify'>
                            <li className='list-disc text-xl'>Use  QuizWhiz as a study aid: Our platform should be used to support your learning process. It’s a great tool for confirming answers, understanding different solutions, or assisting when you’re genuinely stuck.</li>
                            <li className='list-disc text-xl'>Always cross-check: Use  QuizWhiz to understand various approaches to a problem. But ensure to cross-check the information and verify your understanding.</li>
                            <li className='list-disc'>Use as a time saver: If you’re running tight on a deadline, our tool can help speed up the process, but it’s not a substitute for learning and understanding the material.</li>
                        </ul>
                    </p>


                    <h3 className='text-2xl font-bold pt-4 pb-2'> Don'ts</h3>
                    <p className='text-xl pb-2 text-justify'>  We automatically collect information about how you use Quizwhiz, including the quizzes you take, your quiz scores, and the duration of your interactions with our platform.</p>


                    <h3 className='text-2xl pt-4 pb-2 font-bold'> Why Cheating is Not Recommended </h3>
                    <p className='text-xl pb-2 text-justify'>Academic dishonesty might seem like a shortcut, but it 
                    hinders your learning process and personal growth. Here are a few reasons why it's not recommended</p>

                    <h1 className='font-bold text-2xl pt-2 pb-2'>Learning</h1>
                    <p className='text-justify text-xl'>
                    School is not just about getting good grades; it's about learning. By not attempting to learn the material, you rob yourself of valuable knowledge and skills.
                    </p>

                    <h1 className='font-bold text-2xl pt-3 pb-2'>Reputation</h1>
                    <p className='text-justify text-xl'>
                    Being known for academic dishonesty can severely affect your reputation among peers, teachers, and potential employers. It's simply not worth the risk.
                    </p>

                    <h1 className='font-bold text-2xl pt-3 pb-2'>Concequence</h1>
                    <p className='text-justify text-xl'>
                    Schools and universities have strict policies against academic dishonesty, which can lead to severe penalties, including expulsion.
                    </p>

                    <h1 className='font-bold text-2xl pt-3 pb-2'>Integrity</h1>
                    <p className='text-justify text-xl'>
                    Lastly, remember that academic integrity is about more than just rules. It's about being honest and fair, values that are important in all aspects of life.
                    </p>

                </Col>
            </Row>
        </Container>
    )
}

export default Acedemic;
