import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Spinner,
    InputGroupText,
    InputGroup,
} from "reactstrap";
import logo from "../../assets/img/quizwhiz-logo.png";
import { IoArrowBackCircleOutline, IoEye } from "react-icons/io5";
import {
    Link,
    useHistory,
    useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { resetpassword } from "../../store/actions/authAction";
import { toast } from "react-toastify";
import { IoMdEyeOff } from "react-icons/io";
const ForgetPassword = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const email = location.state;
    const history = useHistory();
    const { loading } = useSelector((state) => state.user);
    const initialState = {
        password: "",
        confirmPassword: "",
    };
    const [passwordDetail, setPasswordDetail] = useState(initialState);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setPasswordDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const submitHandler = () => {
        if (passwordDetail.password == passwordDetail.confirmPassword) {
            let obj = {
                Email: email,
                newPassword: passwordDetail.password,
            };
            dispatch(
                resetpassword(obj, () => {
                    setPasswordDetail(initialState);
                    history.push("/login");
                })
            );
        } else {
            toast("Password did not Match With Confirm Password");
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleGoBack = () => {
        history.goBack();
    };
    return (
        <Container className="text-center text-white min-h-screen">
            <Row>
                <Col>
                    <img
                        src={logo}
                        alt="logo"
                        className="login-logo mx-auto w-[215px]"
                    />

                    <Form
                        className="mx-auto form-width text-start"
                        onSubmit={(e) => {
                            e.preventDefault();
                            submitHandler();
                        }}
                    >
                        <IoArrowBackCircleOutline
                            size={30}
                            className="d-block hover:cursor-pointer"
                            onClick={handleGoBack}
                        />
                        <h1 className="text-center text-[40px] font-bold my-[20px]">
                            Reset Password
                        </h1>

                        <FormGroup className="mb-3">
                            <Label for="newpassword" className="text-start">
                                Enter New Password{" "}
                                <span className="text-danger">*</span>
                            </Label>

                            <InputGroup>
                                <Input
                                    type={`${
                                        passwordShow ? "text" : "password"
                                    }`}
                                    name="password"
                                    id="newpassword"
                                    placeholder="Enter New Password"
                                    required
                                    className="py-3 rounded-md text-white bg-transparent placeholder"
                                    value={passwordDetail.password}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                                <InputGroupText>
                                    {passwordShow ? (
                                        <>
                                            <IoEye
                                                className="fs--18 cursor-pointer"
                                                onClick={() => {
                                                    setPasswordShow(
                                                        !passwordShow
                                                    );
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <IoMdEyeOff
                                                className="fs--18 cursor-pointer"
                                                onClick={() => {
                                                    setPasswordShow(
                                                        !passwordShow
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label for="confirmpassword" className="text-start">
                                Confim New Password{" "}
                                <span className="text-danger">*</span>
                            </Label>

                            <InputGroup>
                                <Input
                                    type={`${
                                        confirmPasswordShow
                                            ? "text"
                                            : "password"
                                    }`}
                                    name="confirmPassword"
                                    id="confirmpassword"
                                    placeholder="Enter Confirm Password"
                                    required
                                    className="py-3 rounded-md text-white bg-transparent placeholder"
                                    value={passwordDetail.confirmPassword}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                                <InputGroupText>
                                    {confirmPasswordShow ? (
                                        <>
                                            <IoEye
                                                className="fs--18 cursor-pointer"
                                                onClick={() => {
                                                    setConfirmPasswordShow(
                                                        !confirmPasswordShow
                                                    );
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <IoMdEyeOff
                                                className="fs--18 cursor-pointer"
                                                onClick={() => {
                                                    setConfirmPasswordShow(
                                                        !confirmPasswordShow
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <div className="mx-auto text-center">
                            <Button
                                color="primary"
                                className="py-2 my-5 w-75 font-bold bg-[#636ce4] text-xl rounded-lg"
                                disabled={loading}
                            >
                                {loading ? <Spinner size="sm" /> : "Proceed"}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};
export default ForgetPassword;
