import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { Button, Col, Container, Row } from "reactstrap";
import done from "../../assets/img/getstarted.png";
import off from "../../assets/img/off.png";
import { GetSubscriptionDetail } from "../../store/actions/paymentAction";
const Pricing = () => {
    const history = useHistory();
    // NEW CODE STARTS FROM HERER
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.authUser);
    // console.log(user,"its user...")
    const { checkMemberShip, getSubscptinDetail, loading } = useSelector(
        (state) => state.payment
    );
    useEffect(() => {
        if (user?.UserId) {
            dispatch(GetSubscriptionDetail(user.UserId));
        }
    }, [user?.UserId]);

    // NEW CODE ENDS HERE
    const { uid } = useSelector((state) => state.authUser);
    const   handleClick = (e, item) => {
        e.preventDefault();
        if (uid) {
            history.push("/subscription", { item });
        } else {
            toast.warn("First Login");
        }
    };

    const pricing = [
        {
            id: 1,
            img: off,
            time: "yearly",
            price: "3.99",
            currency: "GBP/month",
        },
        {
            id: 2,
            time: "monthly",
            price: "7.99",
            currency: "GBP/month",
        },
    ];

    return (
        <section id="get-started">
            {!getSubscptinDetail?.SubscriptionId && (
                <Container className="pb-[250px] lg:py-10 text-center text-white">
                    <Row>
                        <Col>
                            <h1 className="my-5 text-3xl lg:text-6xl font-bold  flex items-center justify-center">
                                Get Started
                                <img
                                    src={done}
                                    alt="Heading"
                                    className="sm:w-[50px] md:w-[70px] mx-2"
                                />
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col className=" flex gap-4 w-full justify-center flex-wrap">
                            {pricing.map((item) => {
                                return (
                                    <div
                                        className={`flex flex-col gap-3  md:max-w-[450px] w-full   h-[200px] justify-start items-center  rounded-3xl border-2 border-[#687AD5] ${
                                            item.id === 1
                                                ? "bg-gradient-to-b from-[#626EAC] to-[#101841]"
                                                : ""
                                        } overflow-hidden`}
                                    >
                                        <div className=" inline-flex w-full items-end  justify-between">
                                            <h1 className="w-full text-start text-2xl ml-8">
                                                {item.time}
                                            </h1>
                                            {item.id === 1 ? (
                                                <img
                                                    src={item.img}
                                                    alt=""
                                                    className="w-20 h-14 mt-[-7px] "
                                                />
                                            ) : (
                                                <h1 className="w-20 h-14"></h1>
                                            )}
                                        </div>
                                        <div className="inline-flex w-full items-end gap-3">
                                            <h1 className=" text-4xl ml-4 inline-flex justify-start items-end">
                                                {/* <LiaDollarSignSolid /> */}£{" "}
                                                {item.price}
                                            </h1>
                                            <h2 className=" font-medium text-lg">
                                                {item.currency}
                                            </h2>
                                        </div>
                                        <Button
                                            className=" w-[87%] h-14 rounded-full bg-[#7C92FF] text-black font-bold text-xl"
                                            onClick={(e) =>
                                                handleClick(e, item)
                                            }
                                        >
                                            Subscribe
                                        </Button>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>

                    {/* <Row className="mx-auto px-1 get-started-container flex items-center justify-center">
                        <Col className="pt-5 pb-2 text-center">
                            <h1 className="pt-2 pb-3 text-4xl font-bold">
                                Start Learning Faster
                            </h1>
                            <h3 className="py- text-3xl">
                                Get your 5 day Trial for
                            </h3>
                            <h1 className="display-1 fw-bolder text-5xl lg:text-6xl font-bold ">
                                Only <span className="text-[#636ce4]">£0.00</span>
                            </h1>
                            <p className="py-3 fs-3 fw-bold">
                                and then £ 5.99/month
                            </p>
                            <Button
                                className="p-2 px-3 md:px-5 py-4 btn rounded-5 font-bold text-2xl md:text-3xl lg:text-6xl bg-[#636ce4] rounded-3xl"
                                size="lg"
                                color="primary"
                                onClick={handleClick}
                            >
                                Get Started
                            </Button>
                            <p className="py-3 font-bold text-lg">
                                <em>100% Money Back Guarantee / Cancel Anytime</em>
                            </p>
                            <div className="d-flex justify-content-between align-items-center">
                                <img
                                    src={visa}
                                    alt="Heading"
                                    className="getstarted-img-width1"
                                />
                                <img
                                    src={ace}
                                    alt="Heading"
                                    className="getstarted-img-width2"
                                />
                            </div>
                        </Col>
                    </Row> */}
                </Container>
            )}
        </section>
    );
};

export default Pricing;
