import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    ElementsConsumer,
    useStripe,
} from "@stripe/react-stripe-js";
import React from "react";
import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Spinner,
} from "reactstrap";

import { loadStripe } from "@stripe/stripe-js";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addPaymentAction } from "../store/actions/paymentAction";

// let stripePromise = loadStripe(
//     "pk_test_51OUlCAE66tYGrLUMDWjRkd0wfZOSrjcZWGRbvRFzQ2Dk4JK478haIldZxltBMsYoIhuALDTYzMbO9rekvZXAok1F00Y4EeXCzB"
// );

let stripePromise = loadStripe(
    "pk_live_51OaFhzG9qmiKFEWOTdtLHGvt6KcRMp2OCUwPHklilOcca4R01dTQj0pbk7W2Ycq4LwnPxDys62OjRmJeQeoVLlUc00kN3jS53a"
);
const StripeCheckout = ({ AutoBilling, item }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.authUser);
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [stripeError, setStripeError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const stripe = useStripe();
    const handleStripError = (message) => {
        setStripeError(true);
        setErrorMessage(message);
        setTimeout(() => {
            setStripeError(false);
            setErrorMessage("");
        }, 3000);
    };
    return (
        <div>
            <Card className="shadow border-0   p-2 mt-3 ">
                <CardBody className="px-lg-5 pt-lg-2 pb-lg-2">
                    <div className=" d-flex flex-column justify-content-center align-items-center">
                        <h5 className="font-weight-bold">Pay with Card</h5>
                    </div>

                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                            {({ elements, stripe }) => (
                                <Form
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        setPaymentLoader(true);
                                        const cardElement =
                                            elements.getElement(
                                                CardNumberElement
                                            );
                                        const cardElement2 =
                                            elements.getElement(
                                                CardExpiryElement
                                            );
                                        const cardElement3 =
                                            elements.getElement(CardCvcElement);

                                        let { error, paymentMethod } =
                                            await stripe.createPaymentMethod({
                                                type: "card",
                                                card: cardElement,
                                                card: cardElement2,
                                                card: cardElement3,
                                            });
                                        if (error) {
                                            console.log("err,", error);
                                            handleStripError(error.message);
                                            setPaymentLoader(false);
                                        } else {
                                            const result =
                                                await stripe.createToken(
                                                    cardElement
                                                );
                                            // console.log("result", result);
                                            if (result.error) {
                                                handleStripError(error.message);
                                                setPaymentLoader(false);
                                            } else {
                                                //                                                 {
                                                //   "Email": "string",
                                                //   "AmountInCents": 0,
                                                //   "Token": "string",
                                                //   "AutoBilling": true,
                                                //   "BillingCycle": "monthly"
                                                // }
                                                let body = {
                                                    Token: result.token.id,
                                                    Email: user?.UserEmail,
                                                    AmountInCents:
                                                        Number(item?.price) *
                                                        100,
                                                    // AmountInCents:0,
                                                    AutoBilling: AutoBilling,
                                                    BillingCycle: item?.time,
                                                };

                                                dispatch(
                                                    addPaymentAction(
                                                        body,
                                                        (value) => {
                                                            console.log(
                                                                "value",
                                                                value
                                                            );
                                                            if (
                                                                value == false
                                                            ) {
                                                                setPaymentLoader(
                                                                    false
                                                                );
                                                            } else {
                                                                history.push(
                                                                    "/"
                                                                );
                                                                setPaymentLoader(
                                                                    false
                                                                );
                                                                Elements.getElement(
                                                                    CardNumberElement
                                                                ).clear();
                                                                Elements.getElement(
                                                                    CardExpiryElement
                                                                ).clear();
                                                                Elements.getElement(
                                                                    CardCvcElement
                                                                ).clear();
                                                                history.push(
                                                                    "/"
                                                                );
                                                            }
                                                        }
                                                    )
                                                );
                                            }
                                        }
                                    }}
                                >
                                    <>
                                        <FormGroup>
                                            <Label className="form_label">
                                                Card number
                                            </Label>
                                            <div
                                                className="form-control mt-2 d-flex shadow-sm"
                                                style={{
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <i className="fa fa-credit-card"></i>
                                                <div
                                                    style={{
                                                        flexBasis: "90%",
                                                    }}
                                                >
                                                    <CardNumberElement
                                                        required
                                                        options={{
                                                            placeholder:
                                                                "4000 0560 5461 4556",
                                                            style: {
                                                                base: {
                                                                    // backgroundColor: "#232733",
                                                                    fontSize:
                                                                        "16px",
                                                                },
                                                                invalid: {
                                                                    color: "#9e2146",
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label className="form_label">
                                                        Expiry Date
                                                    </Label>
                                                    <div
                                                        className="form-control mt-2 d-flex shadow-sm"
                                                        style={{
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <i className="fa fa-calendar"></i>
                                                        <div
                                                            style={{
                                                                flexBasis:
                                                                    "90%",
                                                            }}
                                                        >
                                                            <CardExpiryElement
                                                                required
                                                                options={{
                                                                    placeholder:
                                                                        "MM/YY",
                                                                    style: {
                                                                        base: {
                                                                            fontSize:
                                                                                "16px",
                                                                        },
                                                                        invalid:
                                                                            {
                                                                                color: "#9e2146",
                                                                            },
                                                                    },
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label className="form_label">
                                                        CVC/CVV
                                                    </Label>
                                                    <div
                                                        className="form-control mt-2 d-flex shadow-sm"
                                                        style={{
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                flexBasis:
                                                                    "80%",
                                                            }}
                                                        >
                                                            <CardCvcElement
                                                                required
                                                                options={{
                                                                    placeholder:
                                                                        "...",
                                                                    style: {
                                                                        base: {
                                                                            fontSize:
                                                                                "16px",
                                                                        },
                                                                        invalid:
                                                                            {
                                                                                color: "#9e2146",
                                                                            },
                                                                    },
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </>
                                    {stripeError && (
                                        <p className="mb-0 my-1 text-danger">
                                            {errorMessage}
                                        </p>
                                    )}
                                    <Button
                                        size="lg"
                                        className="w-full button-design bg-primary text-white hover:bg-primary"
                                        type="submit"
                                        disabled={paymentLoader}
                                    >
                                        {paymentLoader ? (
                                            <Spinner size="sm" />
                                        ) : (
                                            "Pay"
                                        )}
                                    </Button>
                                </Form>
                            )}
                        </ElementsConsumer>
                    </Elements>
                </CardBody>
            </Card>
        </div>
    );
};

export default StripeCheckout;
