import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineCloseCircle } from "react-icons/ai";
import logoImg from "../../assets/img/quizwhiz-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/authAction";
import {
    useHistory,
    useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { GetSubscriptionDetail } from "../../store/actions/paymentAction";
import { toast } from "react-toastify";

const Header = () => {
    const [toggle, setToggle] = useState(false);
    const { uid } = useSelector((state) => state.authUser);
    // console.log("user ID", uid);
    const dispatch = useDispatch();
    const history = useHistory();

    // SO HERER IS THE LOGIC OF GET SUBSCRIPTION..
    const { user } = useSelector((state) => state.authUser);
    const { checkMemberShip, getSubscptinDetail, loading } = useSelector(
        (state) => state.payment
    );
    // console.log(checkMemberShip, "membership found..");
    const isLoggedIn = user && user?.UserId;
    // console.log(isLoggedIn,"User Login found...")

    const hasSubscription = user?.SubscriptionStatus === "active";
    // console.log(hasSubscription,"Subscription found..")
    useEffect(() => {
        if (user?.UserId) {
            dispatch(GetSubscriptionDetail(user.UserId));
        }
    }, [user?.UserId]);
    // I WANT TO CHECK HERE THAT IF USER BUY THE SUBSCCRIPTION..

    const closeMenu = () => {
        setToggle(false);
    };
    function handleScrollToSection() {
        const section = document.getElementById("get-started");

        if (section) {
            // The scrollIntoView options allow for smooth scrolling and other behaviors
            section.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

    useEffect(() => {
        // console.log('document.body:', document.body);
        // console.log('document.body.classList:', document.body.classList);
        if (toggle) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }

        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, [toggle]);

    // if (uid) {
    return (
        <>
            <div className="text-white  z-10">
                <div className="flex items-center justify-between">
                    {/* right side of the navbar */}
                    <div className="flex items-center  hover:cursor-pointer">
                        <div
                            className="text-white"
                            onClick={() => {
                                history.push("/");
                            }}
                        >
                            <img
                                src={logoImg}
                                alt="logo"
                                className="w-[140px] lg:w-[180px]"
                            />
                        </div>
                    </div>

                    {/* middle of the navbar */}
                    <div className="hidden lg:block font-semibold">
                        <ul className="flex gap-5 hover:cursor-pointer">
                            <a href="#faqs" className="text-decoration-none">
                                <h3
                                    className="hover:text-[#636ce4] hover:transition-all ease-linear duration-300  text-[18px]"
                                    onClick={() => {
                                        history.push("/#faqs");
                                    }}
                                >
                                    How It Works
                                </h3>
                            </a>
                            {isLoggedIn && hasSubscription && (
                                <Link
                                    to="/summarize"
                                    className="text-decoration-none"
                                >
                                    <h3 className="hover:text-[#636ce4] hover:transition-all ease-linear duration-300 text-[18px] md:text-[24px] lg:text-[18px]">
                                        Youtube Summarization
                                    </h3>
                                </Link>
                            )}
                            <Link
                                to="/acedemic"
                                className="text-decoration-none "
                            >
                                {" "}
                                <h3 className="hover:text-[#636ce4] hover:transition-all ease-linear duration-300 text-[18px] hidden xl:block">
                                    Academic Honesty Policy
                                </h3>{" "}
                            </Link>

                            <a href="#reviews" className="text-decoration-none">
                                <h3
                                    className="hover:text-[#636ce4] hover:transition-all ease-linear duration-300 text-[18px]"
                                    onClick={() => {
                                        history.push("/#reviews");
                                    }}
                                >
                                    Reviews
                                </h3>
                            </a>

                            {uid && (
                                <Link
                                    to="/myaccount"
                                    className="text-decoration-none "
                                >
                                    {" "}
                                    <h3 className="hover:text-[#636ce4] hover:transition-all ease-linear duration-300 text-[18px] ">
                                        My Account
                                    </h3>{" "}
                                </Link>
                            )}
                        </ul>
                    </div>

                    {/* right side of the navbar */}
                    <div className="hidden lg:block  font-semibold mx-3">
                        <div className="hidden lg:block d-xl-flex">
                            <div className="flex sm:flex-col lg:flex lg:flex-row gap-x-3 gap-y-3 md:py-0 py-2">
                                {uid ? (
                                    <>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dispatch(
                                                    logout(() => {
                                                        history.push("/login");
                                                    })
                                                );
                                            }}
                                            className="px-9 border-2 border-[#636ce4] sm:px-10 sm:py-2  font-semibold rounded-full hover:bg-[#636ce4] hover:transition-all ease-linear duration-300 hover:text-white my-1"
                                        >
                                            Logout
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            onClick={() => {
                                                history.push("/signup");
                                            }}
                                            className="px-9 mx-2 border-2 border-[#636ce4] bg-[#636ce4] hover:transition-all ease-in-out duration-300 sm:px-10 sm:py-2 font-semibold rounded-full hover:bg-transparent hover:text-white my-1"
                                        >
                                            Signup
                                        </button>
                                        <Link to="/login">
                                            <button className="px-9 border-2 border-[#636ce4] sm:px-10 sm:py-2  font-semibold rounded-full hover:bg-[#636ce4] hover:transition-all ease-linear duration-300 hover:text-white my-1">
                                                Login
                                            </button>
                                        </Link>
                                    </>
                                )}
                                {/* CONDITION TO CHECK CAN USER BUY MEMBERSHIP THEN WE HAVE TO SHOW THE BTN OF GET STARTED */}

                                {Object.keys(getSubscptinDetail).length ===
                                    0 && (
                                    // <Link to="/subscription">
                                    <button
                                        onClick={handleScrollToSection}
                                        className="px-7 border-2 border-[#636ce4] bg-[#636ce4] sm:py-2 font-semibold rounded-full hover:bg-transparent  hover:transition-all ease-linear duration-300 hover:text-white my-1"
                                    >
                                        Get Started
                                    </button>
                                    // </Link>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* menu button */}
                    <div className="block cursor-pointer overflow-hidden lg:hidden">
                        {toggle ? (
                            <AiOutlineCloseCircle
                                onClick={() => setToggle(!toggle)}
                                className=" shadow-sm rounded-full hover:text-white mr-2 transition-transform transform scale-95 duration-1000 "
                                size={40}
                            />
                        ) : (
                            <AiOutlineMenu
                                onClick={() => setToggle(!toggle)}
                                className=" mr-2 shadow-sm transition-transform duration-1000 scale-95 rounded hover:text-white"
                                size={40}
                            />
                        )}

                        <ul
                            className={`fixed h-screen w-full md:h-screen bg-[#101841] z-10 top-[85px] transition-all ease-in-out duration-700 lg:hidden
                           ${toggle ? "left-0" : "left-[-100%]"} px-8 py-8`}
                        >
                            <div className="block lg:flex items-center gap-x-11 flex-wrap text-xl">
                                <a
                                    href="#faqs"
                                    className="text-decoration-none"
                                    onClick={() => {
                                        history.push("/#faqs");
                                        closeMenu();
                                    }}
                                >
                                    <h3 className="text-[18px] py-2 md:text-[24px] hover:text-[#636ce4] hover:transition-all ease-in-out duration-300">
                                        How It Works
                                    </h3>
                                </a>

                                {isLoggedIn && hasSubscription && (
                                    <Link
                                        to="/summarize"
                                        className="text-decoration-none"
                                    >
                                        <h3 className="hover:text-[#636ce4] hover:transition-all ease-linear duration-300 text-[18px] md:text-[24px] lg:text-[18px]">
                                        Youtube Summarization
                                        </h3>
                                    </Link>
                                )}

                                <Link
                                    to="/acedemic"
                                    className="text-decoration-none"
                                    onClick={() => {
                                        history.push("/acedemic");
                                        closeMenu();
                                    }}
                                >
                                    <h3 className="text-[18px] py-2 md:text-[24px] hover:text-[#636ce4] hover:transition-all ease-in-out duration-300  xl:block">
                                        Academic Honesty Policy
                                    </h3>
                                </Link>
                                <a
                                    href="#reviews"
                                    className="text-decoration-none"
                                    onClick={() => {
                                        history.push("/#reviews");
                                        closeMenu();
                                    }}
                                >
                                    <h3 className="text-[18px] py-2 md:text-[24px] hover:text-[#636ce4] hover:transition-all ease-in-out duration-300">
                                        Reviews
                                    </h3>
                                </a>

                                {uid && (
                                    <Link
                                        to="/myaccount"
                                        className="text-decoration-none"
                                        onClick={() => {
                                            history.push("/myaccount");
                                            closeMenu();
                                        }}
                                    >
                                        <h3 className="text-[18px] py-2 md:text-[24px] hover:text-[#636ce4]  hover:transition-all ease-in-out duration-300">
                                            My Account
                                        </h3>
                                    </Link>
                                )}
                            </div>

                            <div className="block lg:flex gap-x-1 gap-y-3 md:py-0 py-2">
                                {uid ? (
                                    <>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dispatch(logout());
                                                closeMenu();
                                            }}
                                            className="px-8 mr-2 text-[18px] py-2 md:text-[24px]  w-fit border-2 border-[#636ce4] sm:px-7 sm:py-2 font-semibold rounded-full hover:bg-[#636ce4] hover:transition-all ease-in-out duration-300 hover:text-white my-1 "
                                        >
                                            Logout
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <Link to="/login">
                                            <button className="px-9 mr-2 w-fit border-2 border-[#636ce4] sm:px-10 sm:py-2 font-semibold rounded-full hover:bg-[#636ce4] hover:transition-all ease-in-out duration-300 hover:text-white my-1">
                                                Login
                                            </button>
                                        </Link>
                                        <Link
                                            to="/signup"
                                            onClick={() => closeMenu()}
                                        >
                                            <button className="px-9 mr-2 border-2 border-[#636ce4] bg-[#636ce4] hover:transition-all ease-in-out duration-300 sm:px-10 sm:py-2 font-semibold rounded-full hover:bg-transparent hover:text-white my-1">
                                                Signup
                                            </button>
                                        </Link>
                                    </>
                                )}
                                {!getSubscptinDetail?.SubscriptionId && (
                                    <button
                                        onClick={handleScrollToSection}
                                        className="text-[18px] md:text-[24px] px-3 sm:px-10 border-2 border-[#636ce4] bg-[#636ce4] sm:py-2 font-semibold rounded-full hover:bg-transparent hover:transition-all ease-linear duration-300 hover:text-white my-1"
                                    >
                                        Get Started
                                    </button>
                                )}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
