export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const AUTH_SET_LOADING = "AUTH_SET_LOADING";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";
export const CHECK_MEMBERSHIP = "CHECK_MEMBERSHIP";
export const GET_SUBCTION_DETAIL = "GET_SUBCTION_DETAIL";
export const CONFIRM_CANCEL_MEMBERSHIP = "CONFIRM_CANCEL_MEMBERSHIP";
export const QUESTION_ANS = "QUESTION_ANS";

export const CONTACT = "CONTACT";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";

// YOUTUBE SUMMRIZE..
export const YOUTUBE_SUMMARIZE_SUCCESS = 'YOUTUBE_SUMMARIZE_SUCCESS';
export const YOUTUBE_SUMMARIZE_ERROR = 'YOUTUBE_SUMMARIZE_ERROR';
