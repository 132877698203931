import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Spinner,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import logo from "../../assets/img/quizwhiz-logo.png";
import { IoArrowBackCircleOutline, IoEye } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { signup } from "../../store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IoMdEyeOff } from "react-icons/io";
const Signup = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.user);
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const initialState = {
        email: "",
        password: "",
        confirmPassword: "",
    };
    const [userDetail, setUserDetail] = useState(initialState);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const submitHandler = () => {
        if (userDetail.password == userDetail.confirmPassword) {
            let obj = {
                Email: userDetail.email,
                Password: userDetail.password,
            };
            dispatch(
                signup(obj, () => {
                    history.push({
                        pathname: "/verify",
                        state: userDetail.email,
                    });
                    setUserDetail(initialState);
                })
            );
        } else {
            toast("Password did not match");
        }
    };
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const handleGoBack = () => {
        history.goBack();
    };
    return (
        <Container className=" text-white min-h-screen">
            <Row>
                <Col>
                    <img
                        src={logo}
                        alt="Logo"
                        className=" mx-auto  w-[215px]"
                    />
                    ​
                    <Form
                        className="mx-auto form-width text-start"
                        onSubmit={(e) => {
                            e.preventDefault();
                            submitHandler();
                        }}
                    >
                        <IoArrowBackCircleOutline
                            size={30}
                            className="d-block cursor-pointer"
                            onClick={handleGoBack}
                        />
                        <h1 className="text-center text-[40px] font-bold mb-[20px]">
                            Signup
                        </h1>
                        <FormGroup className="mb-3">
                            <Label for="email" className="text-start">
                                Email address{" "}
                                <span className="text-danger">*</span>
                            </Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter your email"
                                required
                                className="py-3 rounded-md text-white bg-transparent placeholder "
                                value={userDetail.email}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="password" className="text-start">
                                Password <span className="text-danger">*</span>
                            </Label>

                            <InputGroup>
                                <Input
                                    type={`${
                                        passwordShow ? "text" : "password"
                                    }`}
                                    name="password"
                                    id="password"
                                    minLength={7}
                                    placeholder="Enter your password"
                                    required
                                    className="py-3 rounded-md text-white bg-transparent placeholder"
                                    value={userDetail.password}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                                <InputGroupText>
                                    {passwordShow ? (
                                        <>
                                            <IoEye
                                                className="fs--18 cursor-pointer"
                                                onClick={() => {
                                                    setPasswordShow(
                                                        !passwordShow
                                                    );
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <IoMdEyeOff
                                                className="fs--18 cursor-pointer"
                                                onClick={() => {
                                                    setPasswordShow(
                                                        !passwordShow
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="password" className="text-start">
                                Confirm Password{" "}
                                <span className="text-danger">*</span>
                            </Label>

                            <InputGroup>
                                <Input
                                    type={`${
                                        confirmPasswordShow
                                            ? "text"
                                            : "password"
                                    }`}
                                    name="confirmPassword"
                                    id="password"
                                    minLength={7}
                                    placeholder="Enter confirm password"
                                    required
                                    className="py-3 rounded-md text-white bg-transparent placeholder"
                                    value={userDetail.confirmPassword}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                                <InputGroupText>
                                    {confirmPasswordShow ? (
                                        <>
                                            <IoEye
                                                className="fs--18 cursor-pointer"
                                                onClick={() => {
                                                    setConfirmPasswordShow(
                                                        !confirmPasswordShow
                                                    );
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <IoMdEyeOff
                                                className="fs--18 cursor-pointer"
                                                onClick={() => {
                                                    setConfirmPasswordShow(
                                                        !confirmPasswordShow
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        ​
                        <div className="mx-auto text-center">
                            <Button
                                className="py-2 mb-5 btn w-75 font-bold text-xl bg-[#636ce4] rounded-lg"
                                color="primary"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? <Spinner size="sm" /> : "Continue"}
                            </Button>
                        </div>
                        ​
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};
export default Signup;
