import { QUESTION_ANS } from "../types";

const initialState = {
    loading: false,
    question: [],
};

const questionReducer = (state = initialState, action) => {
    switch (action.type) {
        case QUESTION_ANS:
            return {
                ...state,
                question: action?.payload,
                loading: false,
            };
        default:
            return state;
    } 
};

export default questionReducer;
