// import Image from "next/image";
import { Image } from "react-bootstrap";
import LoaderGIf from "../assets/img/loader.gif";
export function LoadingModal() {
   console.log();
    return (
      <>
        
        <div className="fixed  z-10 inset-0 overflow-y-auto bg-[#000b21] bg-opacity-55">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-[#000b21] opacity-75"></div>
            <div className="relative bg-[#e5eff1]  rounded-lg p-8 max-w-md">
              <div className="p-4 gap-3 flex flex-col place-items-center " >
                <Image src={LoaderGIf} alt="" height={100} width={100}></Image>
                <h6 className="text-black">It will take few minutes</h6>
              </div>
            </div>
          </div>
        </div>
      
      </>
    );
}
