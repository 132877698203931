import { React, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Col, Container, Row } from "reactstrap";

const Faq = () => {
    const [faqToggler, setFaqToggler] = useState(false);
    const [faq, setFaq] = useState(
        //Faq Array Of Object
        [
            {
                id: 0,
                question: "Is QUIZWHIZ a substitute for learning?",
                answer: "Absolutely not! QUIZWHIZ should always be used in supplement to studying to help see how your answer compares to AI",
                isOpen: false,
            },

            {
                id: 1,
                question: "How does QUIZWHIZ work?",
                answer: "QUIZWHIZ is a highly sophisticated artifical intelligence algorithm. By feeding it large amounts of data, we can accurately predict answers to your questions.",
                isOpen: false,
            },

            {
                id: 2,
                question: "Is QUIZWHIZ accurate?",
                answer: "Yes! Every answer generated by QUIZWHIZ is generated by our sophisticated AI. We will never recommend an answer that we are not confident in.",
                isOpen: false,
            },

            {
                id: 3,
                question: "Will my answers be flagged as plagiarism?",
                answer: "No! Our artifical intelligence algorithms generate 100% unique answers that cannot be found anywhere on the internet.",
                isOpen: false,
            },

            {
                id: 4,
                question: "Do you offer refunds?",
                answer: "Absolutely! If you're not satisfied with the service, we will provide a full 100% refund.",
                isOpen: false,
            },
        ]
    );

    const toggleHandler = (id) => {
        setFaq((prevState) =>
            prevState.map((item) => ({
                ...item,
                isOpen: item.id === id ? !item.isOpen : false,
            }))
        );
    };

    return (
        <section id="faqs">
            <Container className="my-20 w-75">
                <Row>
                    <Col>
                        <h1 className="text-3xl md:text-4xl lg:text-6xl font-bold text-center py-12">
                            I bet you are wondering...
                        </h1>
                        {faq.map((contain) => (
                            <div
                                key={contain.id}
                                onClick={() => toggleHandler(contain.id)}
                                className={`${
                                    contain.isOpen ? "open" : ""
                                }  transition-all duration-200 cursor-pointer border-b `}
                            >
                                <div className="flex  justify-between py-2">
                                    <div className="flex items-center justify-center text-lg md:text-2xl font-semibold py-1">
                                        {contain.question}
                                    </div>
                                    <span className="flex justify-end items-center">
                                        <IoIosArrowBack
                                            className={`${
                                                contain.isOpen
                                                    ? "rotate-90"
                                                    : ""
                                            }`}
                                        />
                                    </span>
                                </div>
                                <div className="faq-content text-xl pb-3">
                                    {contain.isOpen && <p>{contain.answer}</p>}
                                </div>
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Faq;
