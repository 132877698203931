const initialState = {
    loading: false,
    contact: [],
};

const contactReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case "LOADER":
            return {
                ...state,
                loading: payload,
            };
        case "CONTACT":
            return {
                ...state,
                contact: payload,
            };
        default:
            return state;
    }
};

export default contactReducer; // corrected export statement
