import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    CheckMemberShip,
    GetSubscriptionDetail,
    cancelSubscriptionAction,
    reSubscibeAction,
} from "../../store/actions/paymentAction";
import off from "../../assets/img/off.png";

import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Pricing from "../../components/global/Pricing";

const MyAccount = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.authUser);
    const { uid } = useSelector((state) => state.authUser);
    // TO OPEN THE SUBSCRIPTION MODAL..
    const [openModal, setOpenModal] = useState(false);
    const openToggle = () => setOpenModal(!openModal);

    // TO OPEN THE CANCEL MODAL
    const [cancelModal, setCancelModal] = useState(false);
    const cancelToggle = () => setCancelModal(!cancelModal);
    const { checkMemberShip, getSubscptinDetail, loading } = useSelector(
        (state) => state.payment
    );
    useEffect(() => {
        if (user?.UserId) {
            dispatch(GetSubscriptionDetail(user.UserId));
        }
    }, [user?.UserId]);
    useEffect(() => {
        if (user == null) {
            history.push("/");
        }
    }, [user]);

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    const handleOnCLick = (e) => {
        e.preventDefault();
        if (user?.UserId) {
            history.push("/subscription");
        } else {
            toast("First you login");
        }
    };
    const ResubscribeCLick = (e) => {
        e.preventDefault();
        dispatch(
            reSubscibeAction(user.UserEmail, () => {
                dispatch(GetSubscriptionDetail(user.UserId));
            })
        );
    };
    const CancelHanler = (e) => {
        e.preventDefault();
        dispatch(
            cancelSubscriptionAction(user.UserEmail, () => {
                history.push("/cancelsubscription");
                dispatch(GetSubscriptionDetail(user.UserId));
                cancelToggle();
            })
        );
    };

    const pricing = [
        {
            id: 1,
            img: off,
            time: "yearly",
            price: "3.99",
            currency: "GBP/month",
        },
        {
            id: 2,
            time: "monthly",
            price: "7.99",
            currency: "GBP/month",
        },
    ];
    const handleClick = (e, item) => {
        e.preventDefault();
        if (uid) {
            history.push("/subscription", { item });
        } else {
            toast.warn("First Login");
        }
    };

    return (
        <Container className="min-h-screen pt-[100px]">
            <Row>
                <Col md={6} className="mx-auto pt-3">
                    <h1 className="text-4xl font-bold text-center pt-3 pb-10">
                        My Account
                    </h1>
                    {getSubscptinDetail?.SubscriptionId ? (
                        <>
                            <Row className="flex  mx-auto">
                                <Col lg={4} md={6} className="text-xl">
                                    <span>Email:</span>
                                </Col>
                                <Col lg={6} md={6}>
                                    <span>{user?.UserEmail}</span>{" "}
                                </Col>
                                <Col lg={4} md={6} className="text-xl">
                                    <span>Status:</span>
                                </Col>
                                <Col lg={6} md={6}>
                                    <span>{getSubscptinDetail?.Status}</span>
                                </Col>
                                <Col lg={4} md={6} className="text-xl">
                                    <span>End Date:</span>
                                </Col>
                                <Col lg={6} md={6}>
                                    <span>{getSubscptinDetail?.EndDate}</span>
                                </Col>
                            </Row>

                            {getSubscptinDetail?.Status == "expired" ? (
                                <Row className="mx-auto text-center">
                                    <Col lg={6}>
                                        {getSubscptinDetail?.Status ==
                                            "expired" && (
                                            <Button
                                                color="primary"
                                                onClick={ResubscribeCLick}
                                                className="py-2 my-1 w-75 font-bold text-xl bg-[#636ce4] rounded-lg"
                                            >
                                                {loading ? (
                                                    <Spinner size="md" />
                                                ) : (
                                                    "Resubscribe"
                                                )}
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    <Row className="mx-auto text-center">
                                        <Col>
                                            <Button
                                                color="primary"
                                                onClick={cancelToggle}
                                                disabled={loading}
                                                className="py-2 my-1 mt-3 font-bold text-xl bg-[#636ce4] rounded-lg text-center"
                                            >
                                                Cancel Subscription
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Row className="justify-center mx-auto">
                                <Col lg={4} md={6} className="text-xl">
                                    <span>Email:</span>
                                </Col>
                                <Col lg={6} md={6}>
                                    <span>{user?.UserEmail}</span>{" "}
                                </Col>

                                <Col className="mx-auto px-3">
                                    <div className="py-3 text-xl text-center">
                                        No Subscription Found
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mx-auto">
                                    <div className="text-center">
                                        <Button
                                            // onClick={ResubscribeCLick}
                                            className="bg-primary hover:bg-primary py-2 my-1 cursor-pointer bg-[#636ce4] font-bold text-xl rounded-lg"
                                            onClick={openToggle}
                                        >
                                            Subscribe
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>
            </Row>

            {/* TO OPEN THE SUBSCIPCETION MODAL.. */}
            <Modal size="lg" centered  isOpen={openModal} toggle={openToggle}>
                <ModalHeader toggle={openToggle}>
                    <div className="modal_subscription">
                        <h3>Get Subscription </h3>
                    </div>
                </ModalHeader>
                <h3 className=" text-white text-xl font-semibold  flex justify-center bg-slate-500  p-3 w-full"> Subscription Plains</h3>
                <ModalBody className="main_modal_subs ">
          
                    {pricing.map((item) => {
                        return (
                            
                            <div
                                className={`flex flex-col gap-3 mr-[20px] modal_subs md:max-w-[450px] w-full  h-[200px] justify-start items-center  rounded-3xl border-2 border-[#687AD5] ${
                                    item.id === 1
                                        ? "bg-gradient-to-b from-[#4E5992] to-[#101841]"
                                        : ""
                                } overflow-hidden`}
                            >
                                <div className=" inline-flex w-full items-end  justify-between">
                                    <h1 className="w-full text-start text-white text-sm ml-8">
                                        {item.time}
                                    </h1>
                                    {item.id === 1 ? (
                                        <img
                                            src={item.img}
                                            alt=""
                                            className="w-20 h-14 mt-[-7px] "
                                        />
                                    ) : (
                                        <h1 className="w-20 h-14"></h1>
                                    )}
                                </div>
                                <div className="inline-flex w-full items-end gap-3">
                                    <h1 className=" text-2xl ml-4 inline-flex text-white justify-start items-end">
                                        {/* <LiaDollarSignSolid /> */}£{" "}
                                        {item.price}
                                    </h1>
                                    <h2 className=" font-medium text-white text-lg">
                                        {item.currency}
                                    </h2>
                                </div>
                                <Button
                                    className=" w-[87%] h-14 rounded-full bg-[#7C92FF] text-black hover:bg-[#6a7ddb] font-semibold text-xl"
                                    onClick={(e) => handleClick(e, item)}
                                >
                                    Subscribe
                                </Button>
                            </div>
                        );
                    })}
                </ModalBody>
            </Modal>
            {/* CANCEL SUSBCRIPTION MODAL... */}
            <Modal isOpen={cancelModal} toggle={cancelToggle}>
                <ModalHeader toggle={cancelToggle}>
                    <div className="modal_subscription">
                        <h3>Cancel Subscription :-</h3>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <h4>Are You Sure Wants to Cancel the Subscription?</h4>
                    </div>
                    <div className="d-flex justify-content-end align-items-center my-3 mt-5 mb-n1">
                        <Button
                            className="bg-success w-25 mx-2"
                            onClick={(e) => {
                                CancelHanler(e);
                            }}
                            disabled={loading}
                        >
                            {loading ? <Spinner size="sm" /> : "Yes"}
                        </Button>
                        <Button
                            className="bg-danger w-25"
                            onClick={cancelToggle}
                        >
                            No
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </Container>
    );
};

export default MyAccount;
