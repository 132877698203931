import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Main = (props) => {
    const history = useHistory();
    const { uid } = useSelector((state) => state.authUser);
    useEffect(() => {
        if (!uid) {
            history.push("/login");
        }
    }, [uid]);
    return (
        <>
            <div className="min-h-[100vh] text-white w-full bg-[#101841]">
                {window.location.pathname == "/subscription" ? null : (
                    <Header />
                )}

                {props.children}

                {window.location.pathname == "/subscription" ? null : (
                    <Footer />
                )}
            </div>
        </>
    );
};

export default Main;
