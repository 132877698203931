import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";

const Default = props => {
   
	return (
		<>
			<div className="min-h-[100vh] text-white w-full bg-[#101841]">
			{
				window.location.pathname == '/subscription' ? null : <Header/>
			}
			
			{props.children}
			
			{
				window.location.pathname == '/subscription' ? null : <Footer/>
			}
			
			</div>
		</>
	);
};

export default Default;
