import React, { useState, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { TbPlayerTrackNext } from "react-icons/tb";
import "react-quill/dist/quill.snow.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { MdDownload } from "react-icons/md";
import { jsPDF } from "jspdf";
import { useReactToPrint } from "react-to-print";
import TextDisplayForPrint from "./TextDisplayForPrint ";
import { toast } from "react-toastify";
import { IoArrowBackCircleOutline } from "react-icons/io5";

const Youtube = () => {
    const location = useLocation();
    const [isPrinting, setIsPrinting] = useState(false);
    const [value, setValue] = useState("");
    const initialState = location.state?.videoId || "";
    const [exportLoader, setExportLoader] = useState(false);
    const [videoId, setVideoId] = useState(initialState);
    const history = useHistory();
    const ytSummarize = useSelector((state) => state?.payment?.ytSummarize);
    console.log(ytSummarize, "its yt data...");

    const componentPDF = useRef();
    const generatePDF = useReactToPrint({
        content: () => {
            if (!value.trim()) {
                alert("No content to print");
                return;
            }
            const editorMain = document.querySelector(".ql-editor");
            if (!editorMain) {
                alert("Editor content not found");
                return;
            }
            // Using innerHTML to keep the styles
            const contentHTML = editorMain.innerHTML;
            const tempPrintElement = document.createElement("div");
            tempPrintElement.style.display = "block"; // Ensure it is visible
            tempPrintElement.style.margin = "20mm"; // Add margins around the content
            tempPrintElement.style.padding = "10mm"; // Optional: Add padding inside the content box
            tempPrintElement.innerHTML = contentHTML; // Assign innerHTML to maintain styles
            document.body.appendChild(tempPrintElement);
            componentPDF.current = tempPrintElement;
            setIsPrinting(true);
            return componentPDF.current;
        },
        documentTitle: "Userdata",
        onBeforePrint: () => {
            // This ensures the printing element is visible during printing
            if (componentPDF.current) {
                componentPDF.current.style.display = "block";
            }
        },
        onAfterPrint: () => {
            // Clean up after printing
            // if (isPrinting) { // Only show toast if printing was initiated
            //     toast("Data saved in PDF");
            //     setIsPrinting(false);  // Reset printing status
            // }
            // else{
            //     console.log("Processed Cancel..")
            // }
            if (componentPDF.current) {
                document.body.removeChild(componentPDF.current);
            }
        },
    });
    const handleCardClick = (topic, explanation) => {
        // Decode HTML entities if explanation and topic are encoded
        const decodedExplanation = decodeHTML(explanation);
        // Remove quotation marks from the topic and decode HTML entities
        const decodedTopic = decodeHTML(topic.replace(/"/g, ""));
    
        setValue((prevValue) => {
            // Convert the previous value HTML back to raw text before appending
            const plainTextPrevValue = decodeHTML(prevValue);
            // Append new content with the topic bolded
            return `${plainTextPrevValue.trim()}<br><strong>Topic: ${decodedTopic}</strong><br>${decodedExplanation}<br>`;
        });
    };
    
    // Decoding HTML Entities
    function decodeHTML(html) {
        var textArea = document.createElement("textarea");
        textArea.innerHTML = html;
        return textArea.value;
    }
    
    // THIS IS USED TO GENERATE ALL THE NOTES
    const handleGenerateAllNotesClick = () => {
        if (ytSummarize && ytSummarize.detail.Summary) {
            // Join each summary with HTML break tags for separation
            const allNotes = ytSummarize.detail.Summary.map(
                (summary, index) =>
                    `<strong>Topic: ${summary.Topic.replace(/"/g, "")}</strong><br>${
                        summary.Explanation
                    }` // Adding additional <br> for spacing
            ).join("<br><br>"); // Ensure there are two breaks between each topic and explanation for clarity
    
            setValue(allNotes); // Set the value which updates the ReactQuill component
        }
    };
    

    return (
        <>
            <Container className="h-[1200px]">
                <Row className="flex justify-between final_class">
                    <Col className="col-md-8">
                        <div>
                            <IoArrowBackCircleOutline
                                size={30}
                                className="d-block cursor-pointer mb-1"
                                onClick={() => history.push("/summarize")}
                            />
                            <div>
                                <h3 className="font-semibold">NotePad</h3>
                            </div>

                            <div className=" mt-3 ql_container relative bg-white text-black rounded-md p-2 w-[100%] h-[580px]">
                                {exportLoader ? (
                                    "Exporting..."
                                ) : (
                                    <span
                                        onClick={generatePDF}
                                        className=" absolute main_export right-[16px] top-[19px] cursor-pointer flex gap-1 items-center hover:text-blue-800"
                                        disabled={exportLoader}
                                    >
                                        <MdDownload />
                                        <h5 className="font-semibold">
                                            Export
                                        </h5>
                                    </span>
                                )}
                                <div>
                                    <div ref={componentPDF}>
                                        <TextDisplayForPrint
                                            content={value}
                                            setValue={setValue}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-md-4 ">
                        <div>
                            <div className="pt-2 font-semibold">
                                <h2>
                                    {ytSummarize
                                        ? ytSummarize?.detail?.VideoInfo?.Title
                                        : "Untitled Lecture"}
                                </h2>
                            </div>
                            <div className="pt-3 z-10">
                                <iframe
                                    width="100%"
                                    height="200px"
                                    src={
                                        videoId
                                            ? `https://www.youtube.com/embed/${videoId}`
                                            : `https://www.youtube.com/embed/`
                                    }
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                    className="rounded-2xl"
                                ></iframe>
                            </div>
                            <div className="cards-container">
                                {ytSummarize &&
                                    ytSummarize?.detail?.Summary?.map(
                                        (summary, index) => (
                                            <div
                                                key={index}
                                                className="cardsssss cursor-pointer"
                                                onClick={() =>
                                                    handleCardClick(
                                                        summary?.Topic,
                                                        summary?.Explanation
                                                    )
                                                }
                                            >
                                                <div className="cardssss-body ">
                                                    <h2 className="text-blue-600 font-semibold pb-2">
                                                        {summary?.Topic?.replace(
                                                            /"/g,
                                                            ""
                                                        ) || "NO DATA FOUND"}
                                                    </h2>
                                                    <p className="truncate-text">
                                                        {summary?.Explanation ||
                                                            "NO DATA FOUND"}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    )}
                            </div>
                            <div className="ml-3 pt-4 flex justify-center">
                                <span className="ml-2 flex gap-1 bg-slate-400 rounded-full items-center w-max px-[17px] py-[10px] justify-center cursor-pointer hover:bg-slate-600" onClick={handleGenerateAllNotesClick}>
                                    <TbPlayerTrackNext />
                                    <button
                                        className=" flex items-center justify-center"
                                        
                                    >
                                        Generate all notes
                                    </button>
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Youtube;
