import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { GiStarShuriken } from "react-icons/gi";
import { TfiReload } from "react-icons/tfi";
import { MdDownload } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaRedditSquare } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoLinkSharp } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { RxAvatar } from "react-icons/rx";
import { FaYoutube } from "react-icons/fa6";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import domToImage from "dom-to-image";
import { youtubeSummarizeAction } from "../store/actions/paymentAction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
const YtSummarize = () => {
    const [input, setInput] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [regenLoader, setregenLoader] = useState(false);
    const [generateLoader, setGenerateLoader] = useState(false);
    const [exportLoader, setExportLoader] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { user } = useSelector((state) => state.authUser);
    const initialState = location.state?.videoId || "";
    const [youtubeURL, setYoutubeURL] = useState('');
    const [videoId, setVideoId] = useState(initialState);
    const ytSummarize = useSelector((state) => state?.payment?.ytSummarize);
    // console.log(ytSummarize, "its yt data...");
    // const { youtubeURL } = location?.state || {};
    // console.log(youtubeURL,"its yt url....")
    useEffect(() => {
        if (location.state && location.state.youtubeURL) {
            setYoutubeURL(location.state.youtubeURL);
        }
    }, [location.state]);
    useEffect(() => {
        if (user?.SubscriptionStatus !== "active") {
            history.push("/");
        }
    }, []);

    const dateOnly = ytSummarize?.detail?.VideoInfo?.Date?.split("T")[0];

    const keyPointsArray = ytSummarize?.detail?.Keypoints
        ? ytSummarize.detail.Keypoints.replace(/###/g, "") // Remove '###'
              .split("\n") // Split into lines
              .filter((point) => point.trim() !== "") // Filter out empty lines
              .map((point) => point.replace(/^Point:\s*/, "").trim()) // Remove "Point: " prefix
        : [];
    const handleChange = (event) => {
        setInput(event.target.value);
        setIsValid(true);
    };

    const exportPdf = () => {
        const contentToExport = document.querySelector(".content-to-export");
        setExportLoader(true);
        if (contentToExport) {
            const pdf = new jsPDF();
            const lineHeight = 10; // Adjust line height as needed
            let yPosition = 10;

            // Replace 'SECTION_BREAK' with the actual string you use to separate sections
            const sections = contentToExport.innerText.split("SECTION_BREAK");

            sections.forEach((section, index) => {
                const lines = pdf.splitTextToSize(section.trim(), 180); // Adjust width as needed
                pdf.text(lines, 10, yPosition);
                // Only add extra space if this is not the last section
                if (index < sections.length - 1) {
                    yPosition += (lines.length + 1) * lineHeight; // Add extra space after section
                } else {
                    yPosition += lines.length * lineHeight; // No extra space after the last section
                }
            });
            setExportLoader(false);

            pdf.save("exported_content.pdf");
        }
    };
    const validateYouTubeUrl = (url) => {
        const regex = /^(https:\/\/youtu.be\/([a-zA-Z0-9_-]+)\?|https:\/\/www.youtube.com\/watch\?v=([a-zA-Z0-9_-]+)(\?[^&\s]*)?|https:\/\/youtu.be\/([a-zA-Z0-9_-]+)$|https:\/\/www\.youtube\.com\/shorts\/([a-zA-Z0-9_-]+)$|https:\/\/youtube\.com\/shorts\/([a-zA-Z0-9_-]+)\?|https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+))/;
        return regex.test(url);
    };
    // Handle form submit for page no 2
    const handleSummarize = () => {
        if (input.trim() === "") {
            setIsValid(true);
            return;
        }

        if (validateYouTubeUrl(input)) {
            setIsValid(true);
            setregenLoader(true);
            setGenerateLoader(true);
            dispatch(
                youtubeSummarizeAction(
                    input,
                    (onSuccess) => {
                        // toast.success(onSuccess);
                        setInput("");
                        setregenLoader(false);
                        setGenerateLoader(false);
                        setYoutubeURL(input);
                        let newVideoId = null;
                        const urlObj = new URL(input);
                        if (urlObj.hostname === "youtu.be") {
                            newVideoId = urlObj.pathname.split("/")[1];
                        } else if (urlObj.pathname.startsWith("/shorts/")) {
                            newVideoId = urlObj.pathname.split("/")[2];
                        } else {
                            newVideoId = urlObj.searchParams.get("v");
                        }
                        setVideoId(newVideoId);
                        setYoutubeURL(input);
                        console.log('Video ID updated to:', newVideoId); // Log the new video ID
                    },
                    (onError) => {
                        toast.error(onError);
                        setInput("");
                        setVideoId("");
                        setregenLoader(false);
                        setGenerateLoader(false);
                    }
                )
            );
        } else {
            setIsValid(false);
        }
    };

    const handleRegenerate = () => {
        setGenerateLoader(true);
        console.log('Regenerating summary for Video URL:', youtubeURL); // Log the YouTube URL being used
        dispatch(
            youtubeSummarizeAction(
                youtubeURL,
                (onSuccess) => {
                    console.log(onSuccess);
                    console.log('Summary regenerated successfully for Video ID:', videoId); // Log success
                    setGenerateLoader(false);
                },
                (onError) => {
                    console.error(onError);
                    toast.error("Failed to regenerate summary.");
                }
            )
        );
    };

    return (
        <>
            <Container className="pb-4">
                <Row>
                    <Col className="col-md-7 one_coloum">
                        <IoArrowBackCircleOutline
                            size={30}
                            className="d-block cursor-pointer mb-3"
                            onClick={() => history.push("/summarize")}
                        />
                        <div className="">
                            <h1 className=" text-3xl font-bold">
                                {ytSummarize?.detail?.VideoInfo?.Title ||
                                    "No Data found"}
                            </h1>
                            <Col className="col-md-5 coloum_middle pt-3 ">
                                <div>
                                    <iframe
                                        width="100%"
                                        height="200px"
                                        src={
                                            videoId
                                                ? `https://www.youtube.com/embed/${videoId}`
                                                : `https://www.youtube.com/embed/`
                                        }
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                        className="rounded-2xl"
                                    ></iframe>
                                </div>
                                <div className="main_grid_section">
                                    <div className="flex flex-col gap-2 pt-2">
                                        <span>
                                            <p className="text-gray-500">
                                                Channel
                                            </p>
                                        </span>
                                        <div className="flex items-center gap-2 text-nowrap">
                                        <img src={ytSummarize?.detail?.VideoInfo?.ChannelProfilePhoto  || <RxAvatar/>} alt="Channel Avatar" className="w-6 h-6 rounded-full" />
                                            <h3>
                                                {ytSummarize?.detail?.VideoInfo
                                                    ?.Channel ||
                                                    "No Data found"}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 pt-2 text-nowrap">
                                        <span>
                                            <p className="text-gray-500">
                                                Subscribers
                                            </p>
                                        </span>
                                        <div className="flex items-center gap-2 text-nowrap">
                                            <h3>
                                                {ytSummarize?.detail?.VideoInfo
                                                    ?.SubscriberCount ||
                                                    "No Data found"}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 pt-2">
                                        <span>
                                            <p className="text-gray-500">
                                                Views
                                            </p>
                                        </span>
                                        <div className="flex items-center gap-2">
                                            <h3>
                                                {ytSummarize?.detail?.VideoInfo
                                                    ?.Views || "No Data found"}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 pt-2">
                                        <span>
                                            <p className="text-gray-500">
                                                Date
                                            </p>
                                        </span>
                                        <div className="flex items-center gap-2">
                                            <h3>
                                                <h3>
                                                    {dateOnly ||
                                                        "No Data found"}
                                                </h3>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="font-semibold pt-4">
                                    <h3>Summarize another video</h3>
                                </div>
                                <div className="flex justify-center mt-3 items-center">
                                    <FaYoutube
                                        className="color_icon_summarize relative left-10 z-[1] bg-white  "
                                        size={30}
                                    />
                                    <input
                                        type="text"
                                        className="ytsummarize_input"
                                        placeholder="https://www.youtube.com/..."
                                        value={input}
                                        onChange={handleChange}
                                    />
                                    <div className="relative">
                                        <button
                                            type="submit"
                                            onClick={handleSummarize}
                                            className={`h-[50px] w-24  rounded-tr-lg rounded-br-lg
                                               text-white text-sm font-medium right-[0px] z-[1] p-2 absolute bottom-[-25px]  cursor-pointer ${
                                                   input
                                                       ? "bg-blue-500"
                                                       : "bg-gray-500"
                                               }`}
                                            disabled={regenLoader}
                                        >
                                            {regenLoader ? (
                                                <Spinner
                                                    size="sm"
                                                    className="spinner_loader"
                                                />
                                            ) : (
                                                "Summarize"
                                            )}
                                        </button>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center">
                                    {!isValid && (
                                        <div className="text-red-500">
                                            Please enter a valid URL.
                                        </div>
                                    )}
                                </div>
                            </Col>

                            <div className="content-to-export">
                                <div className="flex gap-2 items-center pt-3 pb-3">
                                    <GiStarShuriken size={20} />
                                    <h3 className="text-2xl">Summary</h3>
                                </div>
                                {generateLoader || regenLoader ? (
                                    <div className="h-32 flex justify-center items-center">
                                        <Spinner size="md" />
                                    </div>
                                ) : (
                                    <p>
                                        {ytSummarize?.detail?.Summary ||
                                            "No Data Found"}
                                    </p>
                                )}

                                <div className="pt-7 ">
                                    <h3 className="pb-3 font-semibold">
                                        Key Points:
                                    </h3>
                                    {generateLoader || regenLoader ? (
                                        <div className="h-32 flex justify-center items-center">
                                            <Spinner size="md" />
                                        </div>
                                    ) : (
                                        <ul className="pl-4">
                                            {keyPointsArray.length > 0 ? (
                                                keyPointsArray.map(
                                                    (point, index) => (
                                                        <li
                                                            key={index}
                                                            className="list-disc"
                                                        >
                                                            {point}
                                                        </li>
                                                    )
                                                )
                                            ) : (
                                                <li className="list-disc">
                                                    No Data found
                                                </li>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-end pt-4 gap-1 items-center pr-2 ">
                                <div className="cursor-pointer flex gap-1 items-center pr-2">
                                    {generateLoader ? (
                                        <button
                                            className="flex gap-1 items-center hover:text-gray-400"
                                            // onClick={handleRegenerate}
                                            // disabled={generateLoader}
                                        >
                                            <TfiReload />
                                            Regenerate
                                        </button>
                                    ) : (
                                        <button
                                            className="flex gap-1 items-center hover:text-gray-400"
                                            onClick={handleRegenerate}
                                            // disabled={generateLoader}
                                        >
                                            <TfiReload />
                                            Regenerate
                                        </button>
                                    )}
                                </div>
                                {exportLoader ? (
                                    "Exporting..."
                                ) : (
                                    <div
                                        onClick={exportPdf}
                                        className="cursor-pointer flex gap-1 items-center hover:text-gray-400"
                                        disabled={exportLoader}
                                    >
                                        <MdDownload />
                                        <h5>Export</h5>
                                    </div>
                                )}
                            </div>

                            <div className="flex gap-2 items-center">
                                <GiStarShuriken size={20} />
                                <h3 className="text-2xl">Share this summary</h3>
                            </div>
                            {/* *******LAST SHARE ICONS ******* */}
                            <div className="flex gap-6 pt-4 last_icons">
                                <div className="flex flex-col items-center cursor-pointer">
                                    <IoLinkSharp size={26} />
                                    <h3>Link</h3>
                                </div>
                                <div className="flex flex-col items-center cursor-pointer">
                                    <FaFacebook size={26} />
                                    <h3>Facebook</h3>
                                </div>
                                <div className="flex flex-col items-center cursor-pointer">
                                    <FaXTwitter size={26} />
                                    <h3>Twitter</h3>
                                </div>
                                <div className="flex flex-col items-center cursor-pointer">
                                    <FaRedditSquare size={26} />
                                    <h3>Reddit</h3>
                                </div>
                                <div className="flex flex-col items-center cursor-pointer">
                                    <MdEmail size={26} />
                                    <h3>Email</h3>
                                </div>
                                <div className="flex flex-col items-center cursor-pointer">
                                    <IoLogoWhatsapp size={26} />
                                    <h3>Whatsapp</h3>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-md-5 last_coloum sticky top-6 h-max">
                        <div>
                            <iframe
                                width="100%"
                                height="200px"
                                src={
                                    videoId
                                        ? `https://www.youtube.com/embed/${videoId}`
                                        : `https://www.youtube.com/embed/`
                                }
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                                className="rounded-2xl"
                            ></iframe>
                        </div>
                        <div className="main_grid_section">
                            <div className="flex flex-col gap-2 pt-2">
                                <span>
                                    <p className="text-gray-500">Channel</p>
                                </span>
                                <div className="flex items-center gap-2">
                                <img src={ytSummarize?.detail?.VideoInfo?.ChannelProfilePhoto  || <RxAvatar/>} alt="Channel Avatar" className="w-6 h-6 rounded-full" />
                                    <h3>
                                        {ytSummarize?.detail?.VideoInfo
                                            ?.Channel || "No Data found"}
                                    </h3>
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 pt-2">
                                <span>
                                    <p className="text-gray-500">Subscribers</p>
                                </span>
                                <div className="flex items-center gap-2">
                                    <h3>
                                        {" "}
                                        <h3>
                                            {ytSummarize?.detail?.VideoInfo
                                                ?.SubscriberCount ||
                                                "No Data found"}
                                        </h3>
                                    </h3>
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 pt-2">
                                <span>
                                    <p className="text-gray-500">Views</p>
                                </span>
                                <div className="flex items-center gap-2">
                                    <h3>
                                        {" "}
                                        {ytSummarize?.detail?.VideoInfo
                                            ?.Views || "No Data found"}
                                    </h3>
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 pt-2">
                                <span>
                                    <p className="text-gray-500">Date</p>
                                </span>
                                <div className="flex items-center gap-2">
                                    <h3>{dateOnly || "No Data found"}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="font-semibold pt-4">
                            <h3>Summarize another video</h3>
                        </div>
                        <div className="flex justify-center mt-3 items-center ml-[-28px]">
                            <FaYoutube
                                className="color_icon_summarize relative left-10 z-[1] bg-white  "
                                size={30}
                            />
                            <input
                                type="text"
                                className="ytsummarize_input"
                                placeholder="https://www.youtube.com/..."
                                value={input}
                                onChange={handleChange}
                            />
                            <div className="relative">
                                <button
                                    type="submit"
                                    onClick={handleSummarize}
                                    className={`h-[50px]  w-24 rounded-tr-lg rounded-br-lg
                                               text-white text-sm font-medium right-[0px] z-[1] p-2 absolute bottom-[-25px]  cursor-pointer ${
                                                   input
                                                       ? "bg-blue-500"
                                                       : "bg-gray-500"
                                               }`}
                                    disabled={regenLoader}
                                >
                                    {regenLoader ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        "Summarize"
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            {!isValid && (
                                <div className="text-red-500">
                                    Please enter a valid URL.
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default YtSummarize;
