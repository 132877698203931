import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Spinner,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import logo from "../../assets/img/quizwhiz-logo.png";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/authAction";
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";
const Login = () => {
    const dispatch = useDispatch();

    const { loading } = useSelector((state) => state.user);
    const [loginDetail, setLoginDetail] = useState({
        email: "",
        password: "",
    });
    const [passwordShow, setPasswordShow] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const submitHandler = () => {
        let obj = {
            Email: loginDetail.email,
            Password: loginDetail.password,
        };
        dispatch(
            login(obj, () => {
                let path = localStorage.getItem("path");
                if (path != null) {
                    history.push(path);
                }
            })
        );
    };

    const history = useHistory();
    const handleGoBack = () => {
        // history.goBack();
        history.push("/");
    };
    return (
        <Container className="text-center text-white min-h-screen">
            <Row>
                <Col>
                    <img
                        src={logo}
                        alt="logo"
                        className="login-logo mx-auto w-[215px]"
                    />

                    <Form
                        className="mx-auto form-width text-start"
                        onSubmit={(e) => {
                            e.preventDefault();
                            submitHandler();
                        }}
                    >
                        {" "}
                        {/* Remove 'mx-auto text-center' classes */}
                        <IoArrowBackCircleOutline
                            size={30}
                            className="d-block hover:cursor-pointer"
                            onClick={handleGoBack}
                        />
                        <h1 className="text-center text-[40px] font-bold mb-[20px]">
                            Login
                        </h1>
                        <FormGroup className="mb-3">
                            <Label for="email" className="text-start">
                                Email address{" "}
                                <span className="text-danger">*</span>
                            </Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter your email"
                                required
                                className="py-3 rounded-md text-white bg-transparent placeholder"
                                value={loginDetail.email}
                                onChange={(e) => handleChange(e)}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="password" className="text-start">
                                Password <span className="text-danger">*</span>
                            </Label>
                            <InputGroup>
                                <Input
                                    type={`${
                                        passwordShow ? "text" : "password"
                                    }`}
                                    name="password"
                                    id="password"
                                    placeholder="Enter your password"
                                    required
                                    className="py-3 rounded-md text-white bg-transparent placeholder"
                                    value={loginDetail.password}
                                    onChange={(e) => handleChange(e)}
                                />
                                <InputGroupText>
                                    {passwordShow ? (
                                        <>
                                            <IoEye
                                                className="fs--18 cursor-pointer"
                                                onClick={() => {
                                                    setPasswordShow(
                                                        !passwordShow
                                                    );
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <IoMdEyeOff
                                                className="fs--18 cursor-pointer"
                                                onClick={() => {
                                                    setPasswordShow(
                                                        !passwordShow
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        {/* <p className="text-red-500">* Password must be greater then 7 characters</p> */}
                        <div className="mt-2 d-flex justify-content-between">
                            <Link to="/signup">
                                {" "}
                                <p className="mb-2 text-secondary ">
                                    Create an Account
                                </p>
                            </Link>
                            <Link
                                to="/forgetpassword"
                                className="mb-2 text-secondary"
                            >
                                Forgot Your Password?
                            </Link>
                        </div>
                        <div className="mx-auto text-center pb-3">
                            <Button
                                color="primary"
                                className="py-2 mt-4 w-75 font-bold text-xl bg-[#636ce4] rounded-lg"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? <Spinner size="sm" /> : "Login"}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};
export default Login;
